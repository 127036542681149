/* global System */
// register component(s) with react habitat

export default {
  ShouldIStayVC: () =>
    System.import(
      /* webpackChunkName: "ShouldIStayVC" */
      './Component'
    ),
}
