import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_video()
})

function dhsv_vc_video() {
  // $('.dhsv_vc_video').click(function () {
  //   $(this).find('.dhsv_vc_video__decor').hide()
  // })
}
