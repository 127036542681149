var Sticky = require('sticky-js')
var sticky = new Sticky('.selector') //eslint-disable-line

let numTimes = localStorage.getItem('first-view')
if (!numTimes) {
  setTimeout($('.first-visit-modal').show(), 100)
}
centerModal()

const checkFirstView = () => {
  if (!numTimes) {
    numTimes = true
    localStorage.setItem('first-view', numTimes)

    $('.first-visit-modal')
      .delay(1000)
      .queue(function (next) {
        $(this).addClass('active')
        next()
      })
      .delay(2200)
      .queue(function (next) {
        $(this).hide()
        next()
      })
  }
}

function centerModal() {
  let $this = $('.custom-logo-wrap')
  $this.css(
    'top',
    Math.max(0, ($(window).height() - $this.outerHeight()) / 2) + 'px'
  )
  $this.css(
    'left',
    Math.max(0, ($this.parent().width() - $this.outerWidth()) / 2) + 'px'
  )
}

$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top
  var elementBottom = elementTop + $(this).outerHeight()

  var viewportTop = $(window).scrollTop()
  var viewportBottom = viewportTop + $(window).height() * 0.7

  return elementBottom > viewportTop && elementTop < viewportBottom
}

// const stickyBanner = () => {
//   var lastScrollTop = 0
//   var scrollTop = undefined
//   var body = $('body')
//   var distance = 30
//
//   function handleScroll() {
//     scrollTop = $(window).scrollTop()
//
//     if (scrollTop < distance) {
//       body.removeClass('sticky-nav')
//       // $('.js-sticky-link').addClass('opened')
//     } else {
//       body.addClass('sticky-nav')
//       // $('.js-sticky-link.opened').removeClass('opened')
//     }
//
//     lastScrollTop
//   }
//
//   handleScroll()
//   $(window).on('scroll', handleScroll)
// }

function checkIsInView() {
  $('.is-in-view, .animate-it, .green-line-decor').each(function () {
    if ($(this).isInViewport()) {
      $(this).addClass('visible')
    } else {
      $(this).removeClass('visible')
    }
  })

  // Update sticky when element become a visible to fix issue with uncorrect trigger time
  $('.selector').each(function () {
    if ($(this).isInViewport() && !$(this).hasClass('visible')) {
      $(this).addClass('visible')
      sticky.update()
    }
  })
}

function smoothScrollTo(target) {
  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
  let headerHeight = $('.banner').outerHeight(),
    offsetTop = target.offset().top
  if (target.length) {
    $('html,body').animate(
      {
        scrollTop: offsetTop < 700 ? 0 : offsetTop - headerHeight,
      },
      1500
    )
  }
}

function fillProgressBar() {
  let siteHeight = $(document).outerHeight() - $(window).height(),
    thisPosition = window.pageYOffset,
    percentagePosition = thisPosition / (siteHeight / 100)
  $('.subnavigation__progress-bar').css({ width: percentagePosition + '%' })
}

function hightlightActiveSection() {
  if ($('body').hasClass('single')) {
    // $sections incleudes all of the container divs that relate to menu items.
    var $sections = $(
      '.vc_row, .entry-title, #start-scene-trigger, #show-trigger'
    ) // entry-title was added to make possible to scroll to top

    // currentScroll is the number of pixels the window has been scrolled
    var currentScroll = window.pageYOffset + $('.banner').outerHeight() + 20
    // $currentSection is somewhere to place the section we must be looking at
    var $currentSection

    // We check the position of each of the divs compared to the windows scroll positon
    $sections.each(function () {
      // divPosition is the position down the page in px of the current section we are testing
      var divPosition = $(this).offset().top

      // If the divPosition is less the the currentScroll position the div we are testing has moved above the window edge.
      // the -1 is so that it includes the div 1px before the div leave the top of the window.
      if (divPosition - 1 < currentScroll) {
        // We have either read the section or are currently reading the section so we'll call it our current section
        $currentSection = $(this)

        // If the next div has also been read or we are currently reading it we will overwrite this value again. This will leave us with the LAST div that passed.
      }
      // This is the bit of code that uses the currentSection as its source of ID
      if ($currentSection && $currentSection[0].hasAttribute('id')) {
        var id = $currentSection.attr('id')
        $('a').removeClass('active')
        $('[href=#' + id + ']').addClass('active')
      }
    })
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages
    // stickyBanner()
    checkIsInView()
    hightlightActiveSection()
    fillProgressBar()

    // $('.share__btn').on('click', function (e) {
    //   e.preventDefault()
    //   $('.share__content').slideToggle()
    //   $(this).parent().toggleClass('active')
    // })

    $('.subnavigation__toggle, .subnavigation__item').click(function () {
      if ($(window).width() < 992) {
        $('.subnavigation__toggle').toggleClass('open')
        $('.subnavigation__content-inner').slideToggle()
      }
    })

    $(window).on('resize scroll', function () {
      checkIsInView()
      fillProgressBar()
      hightlightActiveSection()
    })

    /* Smooth scroll to the anchor on page load */
    // setTimeout(function () {
    //   if (location.hash) {
    //     /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
    //     window.scrollTo(0, 0)
    //     let target = location.hash.split('#')
    //     smoothScrollTo($('#' + target[1]))
    //   }
    // }, 1)
    if (location.hash) {
      /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
      window.scrollTo(0, 0)
      let target = location.hash.split('#')
      setTimeout(function () {
        smoothScrollTo($('#' + target[1]))
      }, 1100)
    }

    /* Smooth scroll to the anchor on click */
    $('a[href*=#]:not([href=#])').on('click', function () {
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        smoothScrollTo($(this.hash))
        return false
      }
    })

    let $coloredSectins = $('.wpb_row:not(.vc_inner)')
    $('body').scrollspy({ target: '.share', offset: 0 })

    $coloredSectins.each((i, el) => {
      if ($(el).hasClass('blue-overlay')) {
        el.dataset.bg = 'blue'
      } else {
        el.dataset.bg = 'white'
      }
    })

    $(window).on('scroll', () => {
      let currentColor = 'white'
      let windowCenter = $(window).scrollTop() + $(window).outerHeight() * 0.22
      $coloredSectins.each((i, el) => {
        if (
          windowCenter >= $(el).offset().top &&
          windowCenter < $(el).offset().top + $(el).outerHeight()
        ) {
          currentColor = el.dataset.bg
          return false
        }
      })

      if ($('body').prop('data-bg') != currentColor) {
        $('body').attr('data-bg', currentColor)
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    checkFirstView()

    $(window).on('resize', function () {
      sticky.update()
    })
  },
}
