import $ from 'jquery'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, ScrollToPlugin)

$(document).ready(function () {
  dhsv_vc_coal_animation()
})

function dhsv_vc_coal_animation() {
  $('.dhsv_vc_coal_animation').each(function () {
    console.log('coal_anim function called') //eslint-disable-line
    console.log(ScrollToPlugin) //eslint-disable-line
    const mobileTitles = Array.from(document.querySelectorAll('.title-mobile'))
    const mobileBottomText = Array.from(
      document.querySelectorAll('.mobile-bottom-text')
    )

    const storyWrapper = document.querySelector('.story-wrapper')
    const storySVG = document.querySelector('#story')
    const backSVG = document.querySelector('#back')
    const finalSVG = document.querySelector('#final')
    const navigationSVG = document.querySelector('#navigation')

    const startTitle = document.querySelector('.title-start')
    const climateTitle = document.querySelector('.title-climate')
    const climateMobileTitle = document.querySelector('.title-mobile-climate')
    const smokeText = document.querySelector('.smoke-text')
    const smokeTextPercentage = document.querySelector('.smoke-text-percentage')
    const chemicalsText = document.querySelector('.chemicals-text')
    const environmentParagraphs = Array.from(
      document.querySelectorAll('.environment-paragraph')
    )
    const environmentTitle = document.querySelector('.environment-title')
    const environmentMobileTitle = document.querySelector(
      '.title-mobile-environment'
    )
    const mountainText = document.querySelector('.mountain-text')
    const floodParagraphs = Array.from(
      document.querySelectorAll('.flood-paragraph')
    )
    const peopleTitle = document.querySelector('.people-title')
    const peopleMobileTitle = document.querySelector('.title-mobile-people')
    const peopleParagraphs = Array.from(
      document.querySelectorAll('.people-paragraph')
    )
    const signParagraphs = Array.from(
      document.querySelectorAll('.sign-paragraph')
    )
    const signTitle = document.querySelector('.sign-title')
    const signMobileTitle = document.querySelector('.title-sign-people')
    const finalParagraph = document.querySelector('.final-paragraph')
    const finalTitle = document.querySelector('.final-title')

    const mountains = storySVG.querySelector('.mountains')
    const grayScene = storySVG.querySelectorAll('.gray-scene')
    const greenScene = storySVG.querySelectorAll('.green-scene')
    const graySceneRocks = storySVG.querySelector('.gray-scene-rocks')
    const grayScenePath = storySVG.querySelector('.gray-scene-path')
    const smallPowerPlant = storySVG.querySelector('.small-power-plant')
    const graySceneFrontLight = storySVG.querySelector(
      '.gray-scene-front-light'
    )
    const graySceneTubes = storySVG.querySelectorAll('.tube')
    const graySceneWagon = storySVG.querySelector('.wagon')
    const graySceneWagonCar = storySVG.querySelector('.wagon-car')
    const graySceneWagonCoals = Array.from(storySVG.querySelectorAll('.coal'))

    const windingTower = storySVG.querySelector('.winding-tower')
    const bucketWheel = storySVG.querySelector('.bucket-wheel')
    const bucketWheelRocksWrappers = Array.from(
      storySVG.querySelectorAll('.bucket-wheel-rocks g')
    )
    const bucketWheelRocks = Array.from(
      storySVG.querySelectorAll('.bucket-wheel-rocks polygon')
    )
    const windingTowerToRotate = Array.from(
      storySVG.querySelectorAll('.winding-tower-to-rotate')
    )
    const tubeClouds = Array.from(storySVG.querySelectorAll('.tube-clouds *'))
    const chemicalsWrapper = storySVG.querySelector('.chemicals-wrapper')
    const chemicals = Array.from(storySVG.querySelectorAll('.chemical'))
    const chemicalsFormulas = Array.from(
      storySVG.querySelectorAll('.chemical text')
    )
    const bucketWheelToRotate = storySVG.querySelector(
      '.bucket-wheel-to-rotate'
    )

    const mountainPieces = Array.from(
      storySVG.querySelectorAll('.mountain-pieces *')
    )
    const mountainExplosion = storySVG.querySelector('.mountain-explosion')
    const mountainCut = storySVG.querySelector('.mountain-cut')

    const pond = storySVG.querySelector('.pond')
    const foxes = Array.from(storySVG.querySelectorAll('.fox'))
    const birds = Array.from(storySVG.querySelectorAll('.bird'))
    const frogs = Array.from(storySVG.querySelectorAll('.frog'))
    const grassToDisappear = Array.from(
      storySVG.querySelectorAll('.grass-to-disappear')
    )
    const pondDryingShapes = Array.from(
      storySVG.querySelectorAll('.pond-drying')
    )
    const bushes = Array.from(storySVG.querySelectorAll('.bushes *'))
    const treesToColor = Array.from(storySVG.querySelectorAll('.tree-to-color'))
    const yellowGrass = Array.from(storySVG.querySelectorAll('.yellow-grass'))

    const waterHousesWrapper = storySVG.querySelector('.water-houses-wrapper')
    const waterHouses = Array.from(storySVG.querySelectorAll('.water-house'))
    const waterHousesHowers = Array.from(
      storySVG.querySelectorAll('.water-house-hower')
    )
    const cliffRocks = Array.from(storySVG.querySelectorAll('.cliff-rocks *'))
    const cliffEnd = storySVG.querySelector('.cliff-end')
    const waterDirt = Array.from(storySVG.querySelectorAll('.water-dirt'))
    const waterLevel = storySVG.querySelector('.water-level')

    const peopleWrapper = storySVG.querySelectorAll('.people-wrapper')
    const housesToDisappear = Array.from(
      storySVG.querySelectorAll('.people-house-to-disappear')
    )
    const personToDisappear = storySVG.querySelector('.person-to-disappear')
    const people = Array.from(storySVG.querySelectorAll('.person'))
    const arms = [
      people[0].querySelectorAll('.arm'),
      people[1].querySelectorAll('.arm'),
      people[2].querySelectorAll('.arm'),
      people[3].querySelectorAll('.arm'),
    ]
    const hands = [
      people[0].querySelector('.hand'),
      people[1].querySelector('.hand'),
      people[2].querySelector('.hand'),
      people[3].querySelector('.hand'),
    ]
    const signs = [
      storySVG.querySelectorAll('.first-sign'),
      people[1].querySelector('.sign'),
      people[2].querySelector('.sign'),
      people[3].querySelector('.sign'),
    ]

    const signScene = storySVG.querySelectorAll('.sign-scene')
    const wagonMan = storySVG.querySelectorAll('.wagon-men')
    const wagonManHands = Array.from(
      storySVG.querySelectorAll('.wagon-men-hand')
    )
    const wagonAndMan = storySVG.querySelector('.men-n-wagon')
    const signClipCircle = storySVG.querySelector('.sign-clip-circle')
    const signCrossLine = storySVG.querySelector('.sign-cross-line')
    const signExplosion = storySVG.querySelector('.sign-explosion')
    const signMountainBack = storySVG.querySelector('.sign-mountain-back')

    const explosionSmokeWrapper = storySVG.querySelector(
      '.explosion-smoke-wrapper'
    )
    const explosionSmoke = Array.from(
      storySVG.querySelectorAll('.explosion-smoke')
    )

    const menWrapper = finalSVG.querySelector('.men-wrapper')
    const men = finalSVG.querySelector('.men')
    const fullBody = finalSVG.querySelector('.full-body')
    const mouth = finalSVG.querySelector('.mouth')
    const finalWagon = finalSVG.querySelector('.wagon')
    const eyes = Array.from(finalSVG.querySelectorAll('.eye'))
    const eyebrows = Array.from(finalSVG.querySelectorAll('.eyebrow'))
    const blackPoints = Array.from(finalSVG.querySelectorAll('.black-point'))
    const iconBack = Array.from(finalSVG.querySelectorAll('.icon-back'))
    const iconContent = Array.from(finalSVG.querySelectorAll('.icon-content'))
    const lungPoints = Array.from(finalSVG.querySelectorAll('.lung-points *'))

    const navLine = navigationSVG.querySelector('.nav-line-indicate')
    const navIndicatiors = Array.from(
      navigationSVG.querySelectorAll('.nav-point-indicate')
    )
    const navClickable = Array.from(
      navigationSVG.querySelectorAll('.nav-point-clickable')
    )
    const navLabels = Array.from(document.querySelectorAll('.nav-label'))
    let viewport = {}

    const mobileLayoutRatio = 0.65

    // ViewBox to animate

    let scenes = {
      init: {
        x: 1165,
        y: 1000,
        w: 2047,
        h: 1047,
      },
      start: {
        x: 1265,
        y: 1100,
        w: 1847,
        h: 847,
      },
      climateNear: {
        x: 1264,
        y: 1166,
        w: 605,
        h: 605,
      },
      climateFar: {
        x: 1012,
        y: 850,
        w: 1110,
        h: 910,
      },
      environmentUp: {
        x: 1012,
        y: 1600,
        w: 1110,
        h: 710,
      },
      environmentFull: {
        x: 1026,
        y: 1195,
        w: 3734,
        h: 2500,
      },
      humans: {
        x: 1026,
        y: 3862,
        w: 3734,
        h: 2500,
      },
      health: {
        x: 2730.7,
        y: 4834.7,
        w: 228,
        h: 200,
      },
    }
    let boxToAttribute = box => box.x + ' ' + box.y + ' ' + box.w + ' ' + box.h

    $(document).ready(function () {
      console.log('document ready') //eslint-disable-line
      updateLayout()
      initAnimation()
      gsap.to(storyWrapper, {
        duration: 0.1,
        opacity: 1,
      })
    })

    window.onresize = function () {
      updateLayout()
    }
    function updateLayout() {
      const containerHeight = window.innerHeight - 50
      viewport = {
        w: window.innerWidth,
        h: containerHeight,
        ratio: window.innerWidth / window.innerHeight,
      }
      gsap.set(storyWrapper, {
        width: '100%',
      })
      if (viewport.ratio < mobileLayoutRatio) {
        viewport.h = containerHeight * mobileLayoutRatio
        viewport.ratio = mobileLayoutRatio
      } else if (viewport.ratio > 2.1) {
        viewport.ratio = 2.1
        gsap.set(storyWrapper, {
          width: containerHeight * 2.1,
        })
      }
      const fullHeightEls = Array.from(
        document.querySelectorAll('.full-page-height')
      )
      fullHeightEls.forEach(el => {
        gsap.set(el, {
          height: viewport.h,
        })
      })
      gsap.set(finalTitle, {
        top: $(window).height() - 200,
      })

      if (viewport.ratio <= mobileLayoutRatio) {
        scenes.init.w = 700
        scenes.start.w = 700
        scenes.init.x = scenes.start.x

        scenes.climateFar.x += 350
        scenes.climateFar.y += 50
        scenes.climateFar.w -= 750
        scenes.climateFar.h -= 100

        scenes.environmentUp.x += 100
        scenes.environmentUp.w -= 400

        scenes.environmentFull.x += 100
        scenes.environmentFull.y -= 1000
        scenes.environmentFull.w -= 400

        scenes.humans.x += 100
        scenes.humans.y += 600
        scenes.humans.w -= 400

        gsap.set(bucketWheel, {
          scale: 0.7,
          transformOrigin: 'left bottom',
        })
        gsap.set(windingTower, {
          x: -1050,
          y: 200,
        })
        gsap.set(graySceneWagon, {
          scale: 0.5,
          transformOrigin: '50% 70%',
        })
        gsap.set(waterHousesWrapper, {
          x: -800,
        })
        gsap.set(peopleWrapper, {
          scale: 1.5,
          svgOrigin:
            scenes.health.x +
            scenes.health.w * 0.5 +
            ' ' +
            (scenes.health.y + scenes.health.h * 0.5),
        })
        gsap.set(people[1], {
          scale: 1.6,
          transformOrigin: '50% 50%',
          x: -600,
          y: 650,
        })
        gsap.set(people[3], {
          scale: 1.2,
          transformOrigin: '50% 50%',
          x: -1000,
          y: 500,
        })
        gsap.set(people[2], {
          x: -750,
          y: -400,
        })
      }

      if (viewport.ratio === mobileLayoutRatio) {
        gsap.set(mobileBottomText, {
          top: containerHeight * mobileLayoutRatio + 60,
        })
        gsap.set([mobileTitles, startTitle], {
          top: containerHeight * mobileLayoutRatio + 20,
        })
      }
    }

    function initAnimation() {
      // ----------------------------------------------------------------------------------
      //
      // Scroll-independent animations

      const windingTowerTimeline = gsap
        .timeline({ repeat: -1, paused: true })
        .from(
          windingTowerToRotate[0],
          {
            duration: 2,
            rotation: -360,
            svgOrigin: '2396.1 1491.9',
            ease: 'none',
          },
          0
        )
        .from(
          windingTowerToRotate[1],
          {
            duration: 2,
            rotation: -360,
            svgOrigin: '2433.7 1491.9',
            ease: 'none',
          },
          0
        )

      const bucketRotationTimeline = gsap
        .timeline({ repeat: -1, paused: true })
        .from(bucketWheelToRotate, {
          duration: 2.5,
          rotation: -360,
          svgOrigin: '1671.7 1626.9',
          ease: 'none',
        })

      let bucketWheelWorks = true
      let bucketWheelRocksTimelines = []
      let bucketWheelRocksWrappersTimelines = []
      bucketWheelRocks.forEach((r, rIdx) => {
        gsap.set(r, {
          scale: 2,
        })
        const tl = gsap
          .timeline({
            paused: true,
            onComplete: () => {
              if (bucketWheelWorks) {
                tl.play(0)
              }
            },
          })
          .to(
            r,
            {
              duration: 0.8,
              x: -70 - 50 * Math.random(),
              y: -50 - 50 * Math.random(),
              rotation: 50,
              ease: 'power1.in',
            },
            rIdx / 5
          )
          .to(
            r,
            {
              duration: 0.2,
              opacity: 0,
            },
            rIdx / 5 + 0.6
          )
        bucketWheelRocksTimelines.push(tl)
      })
      bucketWheelRocksWrappers.forEach((r, rIdx) => {
        const tl = gsap
          .timeline({
            paused: true,
            onComplete: () => {
              if (bucketWheelWorks) {
                tl.play(0)
              }
            },
          })
          .fromTo(
            r,
            {
              rotation: -150,
              svgOrigin: '1671.7 1626.9',
            },
            {
              duration: 0.8,
              rotation: 80,
              svgOrigin: '1671.7 1626.9',
              ease: 'none',
            },
            rIdx / 5
          )
        bucketWheelRocksWrappersTimelines.push(tl)
      })

      let chemicalTimelines = []
      chemicals.forEach(c => {
        const tl = gsap
          .timeline({ paused: true, repeat: -1, delay: Math.random() * 2 })
          .to(c, {
            duration: 2,
            y: 20,
            ease: 'power1.inOut',
          })
          .to(
            c,
            {
              duration: 2,
              y: 0,
              ease: 'power1.inOut',
            },
            '>'
          )
        chemicalTimelines.push(tl)
      })

      function startIntroSceneAnimations() {
        windingTowerTimeline.play(0)
        bucketRotationTimeline.timeScale(0).play(0)
        bucketWheelRocksTimelines.forEach(tl => {
          tl.timeScale(0).play(0)
        })
        bucketWheelRocksWrappersTimelines.forEach(tl => {
          tl.timeScale(0).play(0)
        })
      }

      function pauseIntroSceneAnimations() {
        windingTowerTimeline.pause(0)
        bucketRotationTimeline.timeScale(0).pause(0)
        bucketWheelRocksTimelines.forEach(tl => {
          tl.timeScale(0).pause(0)
        })
        bucketWheelRocksWrappersTimelines.forEach(tl => {
          tl.timeScale(0).pause(0)
        })
      }

      // ----------------------------------------------------------------------------------
      //
      // HTML text block styling to match SVG

      function setupSmokeText() {
        const box = tubeClouds[0].getBoundingClientRect()
        gsap.set(smokeText, {
          top: box.top + box.height * 0.34,
          left: box.left + box.width * 0.2,
          width: box.width * 0.6,
        })
        gsap.set(smokeTextPercentage, {
          fontSize: box.height * 0.24,
        })
        if (viewport.ratio > mobileLayoutRatio) {
          gsap.set(chemicalsText, {
            top: box.top + box.height * 0.5,
            left: box.left + box.width * 0.6,
            width: box.width * 0.35,
            fontSize: box.height * 0.03,
          })
        } else {
          gsap.set(smokeText, {
            fontSize: box.height * 0.045,
          })
        }
      }
      function showText(text) {
        gsap.set(text, {
          display: 'block',
        })
      }
      function hideText(text) {
        gsap.set(text, {
          display: 'none',
        })
      }

      // ----------------------------------------------------------------------------------
      // Scroll-based animations

      // SCENE APPEARS AT START

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#start-scene-trigger',
            scrub: true,
            end: '100% 80%',
            onEnter: () => {
              $('.dhsv_vc_coal_animation').addClass('start-animation')
              console.log('#start-scene-trigger onEnter') //eslint-disable-line

              bucketWheelWorks = true
              startIntroSceneAnimations()
              showText([startTitle])
              gsap.from(navIndicatiors[0], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
            onEnterBack: () => {
              startIntroSceneAnimations()
              showText([startTitle])
            },
            onLeave: () => {
              hideText(startTitle)
              bucketWheelWorks = false
            },
            onLeaveBack: () => {
              $('.dhsv_vc_coal_animation').removeClass('start-animation')
              console.log('#start-scene-trigger onLeaveBack') //eslint-disable-line
            },
          },
        })
        .to(navLabels[0], {
          duration: 0.1,
          opacity: 1,
        })
        .to(
          navIndicatiors[0],
          {
            duration: 0.3,
            attr: { fill: '#41a42e' },
          },
          '>-0.3'
        )
        .to(
          navLine,
          {
            duration: 3,
            attr: { 'stroke-dashoffset': 698 - 140 * 0.7 },
            ease: 'none',
          },
          0
        )
        .from(
          navigationSVG,
          {
            duration: 0.2,
            opacity: 0,
          },
          0.2
        )
        .from(
          startTitle,
          {
            duration: 0.2,
            opacity: 0,
          },
          0
        )
        .fromTo(
          storySVG,
          {
            duration: 0.2,
            attr: {
              viewBox: boxToAttribute(scenes.init),
            },
          },
          {
            attr: {
              viewBox: boxToAttribute(scenes.start),
            },
            ease: 'none',
          },
          0
        )
        .from(
          bucketWheel,
          {
            duration: 0.2,
            opacity: 0,
            x: 30,
          },
          '>+0.1'
        )
        .fromTo(
          [
            bucketRotationTimeline,
            bucketWheelRocksTimelines,
            bucketWheelRocksWrappersTimelines,
          ],
          {
            timeScale: 0,
          },
          {
            duration: 0.2,
            timeScale: 1,
            ease: 'power1.in',
          },
          '>'
        )
        .from(
          windingTower,
          {
            duration: 0.2,
            opacity: 0,
            scale: 0.9,
            transformOrigin: 'center bottom',
          },
          '>+0.15'
        )

        .addLabel('wagonCrossStart', 1.9)
        .to(
          startTitle,
          {
            duration: 0.2,
            opacity: 0,
          },
          'wagonCrossStart'
        )
        .fromTo(
          graySceneWagon,
          {
            x: -1800 - Math.max(1, Math.abs(viewport.ratio)) * 200,
          },
          {
            duration: 2,
            x: 300 + Math.max(1, Math.abs(viewport.ratio)) * 200,
            ease: 'slow(0.1, 0.1, false)',
          },
          'wagonCrossStart'
        )
        .to(
          graySceneWagonCar,
          {
            duration: 0.2,
            rotation: -2,
            transformOrigin: 'center bottom',
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          graySceneWagonCar,
          {
            duration: 0.2,
            rotation: 2,
            transformOrigin: 'center bottom',
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          graySceneWagonCar,
          {
            duration: 0.2,
            rotation: -3,
            transformOrigin: 'center bottom',
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          graySceneWagonCar,
          {
            duration: 0.2,
            rotation: 4,
            transformOrigin: 'center bottom',
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          graySceneWagonCar,
          {
            duration: 0.2,
            rotation: -2,
            transformOrigin: 'center bottom',
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          graySceneWagonCoals[0],
          {
            duration: 0.2,
            x: -10,
            y: -40,
          },
          'wagonCrossStart+=0.1'
        )
        .to(
          graySceneWagonCoals[0],
          {
            duration: 0.2,
            y: 0,
          },
          '>'
        )
        .to(
          graySceneWagonCoals[1],
          {
            duration: 0.2,
            x: -30,
          },
          'wagonCrossStart+=0.5'
        )

      // ZOOM TO THE CLIMATE SCENE

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#zoom-to-factory-trigger',
            scrub: true,
            onEnterBack: () => {
              bucketWheelWorks = true
              startIntroSceneAnimations()
            },
            onLeave: () => {
              pauseIntroSceneAnimations()
            },
          },
        })
        .to(
          navLine,
          {
            duration: 1,
            attr: { 'stroke-dashoffset': 698 - 140 },
            ease: 'none',
          },
          0
        )
        .to(
          bucketRotationTimeline,
          {
            duration: 1,
            timeScale: 0,
          },
          0
        )
        .fromTo(
          [
            bucketRotationTimeline,
            bucketWheelRocksTimelines,
            bucketWheelRocksWrappersTimelines,
          ],
          {
            timeScale: 1,
          },
          {
            duration: 0.5,
            timeScale: 0,
            ease: 'power1.in',
          },
          0
        )
        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.climateNear),
            },
            ease: 'none',
          },
          0
        )
        .fromTo(
          bucketRotationTimeline,
          {
            timeScale: 1,
          },
          {
            duration: 0.5,
            timeScale: 0,
          },
          0.5
        )
        .to(
          bucketWheel,
          {
            duration: 1,
            x: 250 + Math.max(1, Math.abs(viewport.ratio)) * 400,
            ease: 'power1.in',
          },
          0
        )
        .to(
          windingTower,
          {
            duration: 1,
            x: 350 + Math.max(1, Math.abs(viewport.ratio)) * 400,
            ease: 'power1.in',
          },
          0
        )
        .to(
          smallPowerPlant,
          {
            duration: 1,
            x: 450 + Math.max(1, Math.abs(viewport.ratio)) * 400,
            ease: 'power1.in',
          },
          0
        )
        .to(
          mountains,
          {
            duration: 1,
            x: 550 + Math.max(1, Math.abs(viewport.ratio)) * 400,
            ease: 'power1.in',
          },
          0
        )
        .to(
          graySceneFrontLight,
          {
            duration: 1,
            y: 100,
            ease: 'none',
          },
          0
        )
        .to(
          grayScenePath,
          {
            opacity: 0.3,
            ease: 'none',
          },
          0
        )
        .to(
          graySceneRocks,
          {
            opacity: 0.15,
            ease: 'none',
          },
          0
        )

      // SHOW SMOKE SCENE

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#climate-scene-trigger',
            scrub: true,
            onEnter: () => {
              chemicalTimelines.forEach(tl => {
                tl.play()
              })
              showText([climateTitle, chemicalsText, environmentParagraphs[0]])
              gsap.set(smokeText, {
                display: 'flex',
              })
              gsap.from(navIndicatiors[1], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
            onEnterBack: () => {
              chemicalTimelines.forEach(tl => {
                tl.play()
              })
              showText([climateTitle, chemicalsText, environmentParagraphs[0]])
              gsap.set(smokeText, {
                display: 'flex',
              })
            },
            onLeave: () => {
              hideText(
                climateTitle,
                smokeText,
                chemicalsText,
                environmentParagraphs[0]
              )
            },
            onLeaveBack: () => {
              hideText(
                climateTitle,
                smokeText,
                chemicalsText,
                environmentParagraphs[0]
              )
            },
          },
        })
        .to(navIndicatiors[1], {
          duration: 0.3,
          attr: { fill: '#41a42e' },
        })
        .to(
          navLabels[0],
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .to(
          navLabels[1],
          {
            duration: 0.1,
            opacity: 1,
          },
          '>+0.1'
        )
        .to(
          navLine,
          {
            duration: 8,
            attr: { 'stroke-dashoffset': 698 - 140 * 2 },
            ease: 'none',
          },
          0
        )

        .from(
          [climateTitle, climateMobileTitle],
          {
            duration: 0.2,
            opacity: 0,
          },
          0
        )
        .to(
          climateTitle,
          {
            duration: 0.2,
            opacity: 0,
          },
          2
        )

        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.climateFar),
            },
            ease: 'none',
          },
          0.5
        )
        .to(
          mountains,
          {
            duration: 1,
            x: 280,
            y: 880,
            scale: 3.3,
          },
          0.5
        )
        .from(
          greenScene,
          {
            duration: 1,
            y: 1000,
          },
          0.5
        )
        .to(
          graySceneTubes,
          {
            duration: 0.1,
            scaleY: 0.9,
            scaleX: 1.1,
            transformOrigin: 'center bottom',
          },
          0.5
        )
        .to(
          graySceneTubes,
          {
            duration: 0.1,
            scale: 1,
            transformOrigin: 'center bottom',
          },
          0.6
        )
        .from(
          tubeClouds,
          {
            duration: 0.5,
            y: 170,
            ease: 'back(2).out',
          },
          0.6
        )
        .from(
          tubeClouds[0],
          {
            duration: 0.5,
            x: -48,
          },
          0.6
        )
        .from(
          tubeClouds[1],
          {
            duration: 0.5,
            x: 5,
          },
          0.6
        )
        .from(
          tubeClouds[2],
          {
            duration: 0.5,
            x: 57,
          },
          0.6
        )
        .from(
          tubeClouds,
          {
            scale: 0.01,
            transformOrigin: 'center center',
            ease: 'power1.out',
          },
          0.6
        )
        .from(
          tubeClouds,
          {
            opacity: 0.35,
            duration: 0.5,
            ease: 'power2.in',
          },
          1.1
        )

        .call(setupSmokeText, null, 2)
        .from(
          smokeText,
          {
            duration: 0.1,
            opacity: 0,
          },
          2
        )
        .to(
          smokeText,
          {
            duration: 0.1,
            opacity: 0,
          },
          '>+1'
        )

        .from(
          chemicals,
          {
            opacity: 0.2,
            scale: 0,
            transformOrigin: 'center center',
            stagger: 0.04,
            ease: 'back(2).out',
          },
          3.5
        )
        .fromTo(
          chemicalsWrapper,
          {
            scale: 0.6,
            transformOrigin: 'center center',
          },
          {
            scale: 0.75,
            transformOrigin: 'center center',
          },
          3.5
        )
        .from(
          chemicalsText,
          {
            duration: 0.1,
            opacity: 0,
          },
          3.5
        )

        .to(
          chemicalsText,
          {
            duration: 0.2,
            opacity: 0,
          },
          5
        )
        .to(
          chemicalsWrapper,
          {
            duration: 0.5,
            scale: 1,
            transformOrigin: 'center center',
            ease: 'power1.in',
          },
          5
        )
        .from(
          chemicalsFormulas,
          {
            opacity: 0,
            stagger: 0.04,
            ease: 'back(2).out',
          },
          5
        )

        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.environmentUp),
            },
            ease: 'none',
          },
          7
        )
        .to(
          grayScene,
          {
            duration: 1,
            opacity: 0.5,
            ease: 'none',
          },
          7
        )
        .from(
          environmentParagraphs[0],
          {
            duration: 0.1,
            opacity: 0,
          },
          '>+1'
        )
        .to(
          environmentParagraphs[0],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+0.8'
        )

      // SHOW GREEN SCENE

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#environment-scene-trigger',
            scrub: true,
            onEnter: () => {
              showText([
                environmentParagraphs[1],
                environmentParagraphs[2],
                environmentParagraphs[3],
                environmentTitle,
              ])
              gsap.from(navIndicatiors[2], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
            onEnterBack: () => {
              showText([
                environmentParagraphs[1],
                environmentParagraphs[2],
                environmentParagraphs[3],
                environmentTitle,
              ])
            },
            onLeave: () => {
              chemicalTimelines.forEach(tl => {
                tl.pause()
              })
              hideText([
                environmentParagraphs[1],
                environmentParagraphs[2],
                environmentParagraphs[3],
                environmentTitle,
              ])
            },
            onLeaveBack: () => {
              chemicalTimelines.forEach(tl => {
                tl.play()
              })
              hideText([
                environmentParagraphs[1],
                environmentParagraphs[2],
                environmentParagraphs[3],
                environmentTitle,
              ])
            },
          },
        })
        .to(navIndicatiors[2], {
          duration: 0.3,
          attr: { fill: '#41a42e' },
        })
        .to(
          navLabels[1],
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .to(
          navLabels[2],
          {
            duration: 0.1,
            opacity: 1,
          },
          '>+0.1'
        )
        .to(
          navLine,
          {
            duration: 5.5,
            attr: { 'stroke-dashoffset': 698 - 140 * 2.5 },
            ease: 'none',
          },
          0
        )

        .to(
          climateMobileTitle,
          {
            duration: 0.05,
            opacity: 0,
          },
          1
        )
        .from(
          [environmentTitle, environmentMobileTitle],
          {
            duration: 0.2,
            opacity: 0,
          },
          1
        )
        .to(
          environmentTitle,
          {
            duration: 0.5,
            opacity: 0,
          },
          '>+1'
        )

        .from(
          environmentParagraphs[1],
          {
            duration: 0.1,
            opacity: 0,
          },
          1
        )
        .to(
          environmentParagraphs[1],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+1'
        )

        .from(
          environmentParagraphs[2],
          {
            duration: 0.1,
            opacity: 0,
          },
          '>'
        )
        .to(
          environmentParagraphs[2],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+1'
        )

        .from(
          environmentParagraphs[3],
          {
            duration: 0.1,
            opacity: 0,
          },
          '>'
        )
        .to(
          environmentParagraphs[3],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+1'
        )

        .to(
          treesToColor[3],
          {
            duration: 3,
            attr: { fill: '#9FB638' },
          },
          0
        )
        .to(
          treesToColor[1],
          {
            duration: 2.5,
            attr: { fill: '#C89712' },
          },
          0.5
        )
        .to(
          treesToColor[0],
          {
            duration: 2,
            attr: { fill: '#E9BE33' },
          },
          1
        )
        .to(
          treesToColor[2],
          {
            duration: 2,
            attr: { fill: '#E9BE33' },
          },
          1
        )
        .to(
          bushes,
          {
            duration: 2,
            stagger: 0.2,
            opacity: 0,
            scale: 0.5,
            transformOrigin: 'center bottom',
          },
          0
        )
        .to(
          pond,
          {
            duration: 3,
            opacity: 0,
            ease: 'power1.in',
          },
          0
        )
        .to(
          pond,
          {
            duration: 2,
            scale: 0.7,
            transformOrigin: 'center center',
          },
          0
        )
        .from(
          pondDryingShapes,
          {
            duration: 2,
            stagger: 0.2,
            scale: 5,
            transformOrigin: 'center center',
            ease: 'none',
          },
          0
        )
        .to(
          frogs[0],
          {
            duration: 1,
            opacity: 0,
            scale: 0.5,
            transformOrigin: 'center bottom',
            ease: 'power1.in',
          },
          0
        )
        .to(
          frogs[1],
          {
            duration: 2,
            opacity: 0,
            scale: 0.5,
            transformOrigin: 'center bottom',
            ease: 'power2.in',
          },
          0
        )
        .from(
          yellowGrass,
          {
            duration: 1,
            stagger: 0.2,
            opacity: 0,
          },
          0
        )
        .to(
          foxes,
          {
            duration: 0.3,
            stagger: 0.5,
            opacity: 0,
            scale: 0.9,
            transformOrigin: 'center bottom',
          },
          1.5
        )
        .to(
          grassToDisappear,
          {
            duration: 1,
            stagger: 0.2,
            opacity: 0,
            scale: 0.9,
            transformOrigin: 'center bottom',
          },
          0
        )
        .to(
          birds,
          {
            duration: 0.1,
            stagger: 0.9,
            opacity: 0,
          },
          1
        )

        .addLabel('toFullScene', 4.5)
        .to(
          mountains,
          {
            duration: 1,
            y: 80,
          },
          'toFullScene'
        )
        .to(
          grayScene,
          {
            duration: 1,
            y: 900,
          },
          'toFullScene'
        )
        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.environmentFull),
            },
            ease: 'none',
          },
          'toFullScene'
        )

      // SHOW EXPLOSION & TEXT

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#mountain-explosion-trigger',
            scrub: true,
            onEnter: () => {
              showText(mountainText)
            },
            onEnterBack: () => {
              showText(mountainText)
            },
            onLeave: () => {
              hideText(mountainText)
            },
            onLeaveBack: () => {
              hideText(mountainText)
            },
          },
        })
        .from(
          mountainText,
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .set(
          mountainExplosion,
          {
            opacity: 1,
          },
          0.58
        )
        .to(
          mountainExplosion,
          {
            duration: 0.1,
            scale: 2,
            transformOrigin: 'center center',
            ease: 'none',
          },
          0.58
        )
        .to(
          mountainPieces[0],
          {
            duration: 0.2,
            x: 230,
            y: -230,
            rotation: 230,
            ease: 'none',
          },
          0.6
        )
        .to(
          mountainPieces[0],
          {
            duration: 0.05,
            opacity: 0,
          },
          0.75
        )
        .to(
          mountainPieces[1],
          {
            duration: 0.2,
            x: 330,
            y: -30,
            rotation: -20,
            ease: 'none',
          },
          0.6
        )
        .to(
          mountainPieces[1],
          {
            duration: 0.05,
            opacity: 0,
          },
          0.75
        )
        .to(
          mountainPieces[2],
          {
            duration: 0.2,
            x: -230,
            y: -230,
            rotation: 100,
            ease: 'none',
          },
          0.6
        )
        .to(
          mountainPieces[2],
          {
            duration: 0.05,
            opacity: 0,
          },
          0.75
        )
        .to(
          mountainPieces[3],
          {
            duration: 0.2,
            x: 20,
            y: -130,
            ease: 'none',
          },
          0.6
        )
        .to(
          mountainPieces[3],
          {
            duration: 0.05,
            opacity: 0,
          },
          0.75
        )
        .set(
          mountainExplosion,
          {
            opacity: 0,
          },
          0.75
        )
        .fromTo(
          mountainCut,
          {
            opacity: 0,
          },
          {
            duration: 0.01,
            opacity: 1,
          },
          0.75
        )
        .to(
          mountainText,
          {
            duration: 0.2,
            opacity: 0,
          },
          '>'
        )

      // SHOW FLOODING

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#flooding-trigger',
            scrub: true,
            onEnter: () => {
              showText(floodParagraphs)
            },
            onEnterBack: () => {
              showText(floodParagraphs)
            },
            onLeave: () => {
              hideText(floodParagraphs)
            },
            onLeaveBack: () => {
              hideText(floodParagraphs)
            },
          },
        })
        .to(
          navLine,
          {
            duration: 2,
            attr: { 'stroke-dashoffset': 698 - 140 * 3 },
            ease: 'none',
          },
          0
        )
        .to(
          cliffRocks[5],
          {
            duration: 0.5,
            rotation: 100,
            x: 300,
            y: 200,
          },
          0
        )
        .to(
          cliffRocks[1],
          {
            duration: 0.5,
            rotation: 20,
            x: 500,
            y: 400,
          },
          0.1
        )
        .to(
          cliffRocks[0],
          {
            duration: 0.5,
            rotation: 50,
            x: 300,
            y: 200,
          },
          0.1
        )
        .to(
          cliffRocks[3],
          {
            duration: 0.5,
            rotation: 50,
            x: 300,
            y: 200,
          },
          0.2
        )
        .to(
          cliffEnd,
          {
            duration: 0.8,
            rotation: 130,
            transformOrigin: 'center center',
            x: 1000,
            y: 800,
          },
          0.15
        )

        .from(
          waterLevel,
          {
            duration: 0.8,
            scaleY: 0.2,
            transformOrigin: 'center bottom',
          },
          0.15
        )
        .from(
          waterLevel,
          {
            duration: 0.2,
            x: -80,
            ease: 'power1.inOut',
          },
          0.15
        )
        .to(
          waterLevel,
          {
            duration: 0.2,
            x: -80,
            ease: 'power1.inOut',
          },
          '>'
        )
        .to(
          waterLevel,
          {
            duration: 0.2,
            x: 0,
            ease: 'power1.inOut',
          },
          '>'
        )

        .to(
          waterHouses[2],
          {
            duration: 0.2,
            rotation: -40,
            transformOrigin: 'center center',
          },
          0.3
        )
        .to(
          waterHouses[2],
          {
            duration: 2,
            x: 250,
          },
          0.3
        )
        .from(
          waterHousesHowers[2],
          {
            duration: 0.01,
            opacity: 0,
          },
          0.3
        )
        .from(
          waterHousesHowers[2],
          {
            duration: 0.2,
            x: -120,
            y: 30,
          },
          0.3
        )

        .to(
          waterHouses[1],
          {
            duration: 0.2,
            rotation: 30,
            transformOrigin: 'center center',
          },
          0.5
        )
        .to(
          waterHouses[1],
          {
            duration: 2,
            x: 900,
          },
          0.5
        )
        .from(
          waterHousesHowers[1],
          {
            duration: 0.01,
            opacity: 0,
          },
          0.5
        )
        .from(
          waterHousesHowers[1],
          {
            duration: 0.2,
            y: 80,
          },
          0.5
        )

        .to(
          waterHouses[0],
          {
            duration: 0.2,
            rotation: -60,
            transformOrigin: 'center center',
          },
          0.6
        )
        .to(
          waterHouses[0],
          {
            duration: 2,
            x: 800,
          },
          0.6
        )
        .from(
          waterHousesHowers[0],
          {
            duration: 0.01,
            opacity: 0,
          },
          0.7
        )
        .from(
          waterHousesHowers[0],
          {
            duration: 0.2,
            y: 200,
          },
          0.7
        )

        .from(
          waterDirt,
          {
            duration: 0.4,
            stagger: 0.1,
            y: 50,
            scaleX: 0.5,
            scaleY: 0,
            transformOrigin: 'center center',
          },
          1
        )

        .from(
          floodParagraphs[0],
          {
            duration: 0.05,
            opacity: 0,
          },
          0.4
        )
        .to(
          floodParagraphs[0],
          {
            duration: 0.05,
            opacity: 0,
          },
          '>+0.3'
        )
        .from(
          floodParagraphs[1],
          {
            duration: 0.05,
            opacity: 0,
          },
          '>'
        )
        .to(
          floodParagraphs[1],
          {
            duration: 0.1,
            opacity: 0,
          },
          '>+0.3'
        )
        .from(
          floodParagraphs[2],
          {
            duration: 0.1,
            opacity: 0,
          },
          1.5
        )
        .to(
          floodParagraphs[2],
          {
            duration: 0.1,
            opacity: 0,
          },
          '>+0.4'
        )

        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.humans),
            },
            ease: 'none',
          },
          '>-0.2'
        )

      // SHOW PEOPLE, ZOOM INTO THE SIGN

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#people-trigger',
            scrub: true,
            onEnter: () => {
              showText([peopleTitle, peopleParagraphs, signParagraphs[0]])
              gsap.from(navIndicatiors[3], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
            onEnterBack: () => {
              showText([peopleTitle, peopleParagraphs, signParagraphs[0]])
            },
            onLeave: () => {
              hideText([peopleTitle, peopleParagraphs, signParagraphs[0]])
            },
            onLeaveBack: () => {
              hideText([peopleTitle, peopleParagraphs, signParagraphs[0]])
            },
          },
        })
        .to(navIndicatiors[3], {
          duration: 0.3,
          attr: { fill: '#41a42e' },
        })
        .to(
          navLabels[2],
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .to(
          navLabels[3],
          {
            duration: 0.1,
            opacity: 1,
          },
          '>+0.1'
        )
        .to(
          navLine,
          {
            duration: 5.5,
            attr: { 'stroke-dashoffset': 698 - 140 * 4 },
            ease: 'none',
          },
          0
        )

        .to(
          environmentMobileTitle,
          {
            duration: 0.05,
            opacity: 0,
          },
          0
        )
        .from(
          [peopleTitle, peopleMobileTitle],
          {
            duration: 0.2,
            opacity: 0,
          },
          0
        )
        .to(
          peopleTitle,
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+0.5'
        )
        .to(
          housesToDisappear,
          {
            duration: 0.5,
            stagger: 0.3,
            opacity: 0,
          },
          1
        )
        .to(
          personToDisappear,
          {
            duration: 0.5,
            opacity: 0,
          },
          1.2
        )
        .from(
          peopleParagraphs[0],
          {
            duration: 0.1,
            opacity: 0,
          },
          0.8
        )
        .to(
          peopleParagraphs[0],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+0.8'
        )

        .addLabel('raiseSigns', 2)

        .from(
          signs[0],
          {
            duration: 0.4,
            x: -70,
            y: -400,
          },
          'raiseSigns'
        )
        .from(
          signs[0],
          {
            duration: 0.1,
            opacity: 0,
          },
          'raiseSigns'
        )
        .from(
          arms[0],
          {
            duration: 0.4,
            rotation: -20,
            svgOrigin: '2898 5180',
          },
          'raiseSigns'
        )
        .from(
          hands[0],
          {
            duration: 0.4,
            rotation: 80,
            svgOrigin: '2778 5292',
          },
          'raiseSigns'
        )

        .fromTo(
          signs[1],
          {
            rotation: 12,
            transformOrigin: 'center center',
            x: 70,
            y: -300,
          },
          {
            duration: 0.4,
            rotation: 12,
            transformOrigin: 'center center',
            x: 0,
            y: 0,
          },
          'raiseSigns+=0.2'
        )
        .from(
          signs[1],
          {
            duration: 0.1,
            opacity: 0,
          },
          'raiseSigns+=0.2'
        )
        .from(
          arms[1],
          {
            duration: 0.4,
            rotation: 20,
            svgOrigin: '4317 5199',
          },
          'raiseSigns+=0.2'
        )
        .from(
          hands[1],
          {
            duration: 0.4,
            rotation: -80,
            svgOrigin: '4397 5279',
          },
          'raiseSigns+=0.2'
        )

        .fromTo(
          signs[2],
          {
            rotation: -20,
            transformOrigin: 'center center',
            x: -20,
            y: -300,
          },
          {
            duration: 0.4,
            rotation: -20,
            transformOrigin: 'center center',
            x: 30,
            y: -90,
          },
          'raiseSigns+=0.1'
        )
        .from(
          signs[2],
          {
            duration: 0.1,
            opacity: 0,
          },
          'raiseSigns+=0.1'
        )
        .from(
          arms[2],
          {
            duration: 0.4,
            rotation: -20,
            svgOrigin: '4072 5151',
          },
          'raiseSigns+=0.1'
        )
        .from(
          hands[2],
          {
            duration: 0.4,
            rotation: 80,
            svgOrigin: '3962 5265',
          },
          'raiseSigns+=0.1'
        )

        .fromTo(
          signs[3],
          {
            rotation: -12,
            transformOrigin: 'center center',
            x: -20,
            y: -300,
          },
          {
            duration: 0.4,
            rotation: -18,
            transformOrigin: 'center center',
            x: -10,
            y: -65,
          },
          'raiseSigns+=0.3'
        )
        .from(
          signs[3],
          {
            duration: 0.1,
            opacity: 0,
          },
          'raiseSigns+=0.3'
        )
        .from(
          arms[3],
          {
            duration: 0.4,
            rotation: -20,
            svgOrigin: '3404 5395',
          },
          'raiseSigns+=0.3'
        )
        .from(
          hands[3],
          {
            duration: 0.4,
            rotation: 80,
            svgOrigin: '3295 5505',
          },
          'raiseSigns+=0.3'
        )
        .from(
          peopleParagraphs[1],
          {
            duration: 0.1,
            opacity: 0,
          },
          'raiseSigns+=0.1'
        )
        .to(
          peopleParagraphs[1],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+1'
        )

        .addLabel('zoomSign', 3.4)
        .set(
          wagonMan,
          {
            x: 6,
          },
          0
        )
        .to(
          storySVG,
          {
            duration: 1,
            attr: {
              viewBox: boxToAttribute(scenes.health),
            },
            ease: 'none',
          },
          'zoomSign'
        )
        .from(
          signScene,
          {
            duration: 1,
            rotation: -10,
            svgOrigin: '2844.7 4934.7',
            ease: 'none',
          },
          'zoomSign'
        )
        .from(
          signMountainBack,
          {
            duration: 0.7,
            x: -200,
            ease: 'power1.in',
          },
          'zoomSign'
        )
        .to(
          [signClipCircle, greenScene],
          {
            duration: 0.5,
            scale: 3,
            svgOrigin: '2844.7 4934.7',
            ease: 'power1.in',
          },
          'zoomSign+=0.5'
        )
        .to(
          signCrossLine,
          {
            duration: 0.5,
            attr: { 'stroke-dashoffset': 263.8 },
          },
          'zoomSign+=0.5'
        )
        .from(
          wagonMan,
          {
            duration: 0.01,
            opacity: 0,
          },
          'zoomSign+=1'
        )
        .from(
          wagonMan,
          {
            duration: 0.5,
            y: 140,
            rotation: -20,
            transformOrigin: '50% 90%',
          },
          'zoomSign+=1'
        )
        .from(
          wagonManHands[0],
          {
            duration: 0.5,
            x: 5,
            rotation: 40,
            transformOrigin: '20% 0%',
          },
          'zoomSign+=1'
        )
        .from(
          wagonManHands[1],
          {
            duration: 0.5,
            x: -12,
            rotation: 40,
            transformOrigin: '0% 0%',
          },
          'zoomSign+=1'
        )
        .to(
          wagonAndMan,
          {
            duration: 0.8,
            x: 300,
            ease: 'power1.in',
          },
          'zoomSign+=1.4'
        )
        .from(
          signParagraphs[0],
          {
            duration: 0.1,
            opacity: 0,
          },
          'zoomSign+=1.5'
        )
        .to(
          signParagraphs[0],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+=1'
        )

      //

      gsap.timeline({
        scrollTrigger: {
          trigger: '#sign-explosion-trigger',
          start: 'center 100%',
          end: 'center 50%',
          scrub: true,
          onEnter: () => {
            gsap.fromTo(
              signExplosion,
              {
                opacity: 0,
                scale: 1,
              },
              {
                duration: 0.05,
                opacity: 1,
                scale: 2,
                transformOrigin: '17% 35%',
              }
            )
            gsap.fromTo(
              signExplosion,
              {
                opacity: 1,
              },
              {
                delay: 0.25,
                duration: 0.03,
                opacity: 0,
              }
            )
          },
        },
      })

      // SHOW SCENE INSIDE THE SIGN

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#health-trigger',
            scrub: true,
            onEnter: () => {
              showText([signTitle, signParagraphs[1]])
              gsap.from(navIndicatiors[4], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
            onEnterBack: () => {
              showText([signTitle, signParagraphs[1]])
            },
            onLeave: () => {
              hideText([signTitle, signParagraphs[1]])
            },
            onLeaveBack: () => {
              hideText([signTitle, signParagraphs[1]])
            },
          },
        })
        .to(navIndicatiors[4], {
          duration: 0.3,
          attr: { fill: '#41a42e' },
        })
        .to(
          navLabels[3],
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .to(
          navLabels[4],
          {
            duration: 0.1,
            opacity: 1,
          },
          '>+0.1'
        )
        .to(
          navLine,
          {
            duration: 2.5,
            attr: { 'stroke-dashoffset': 698 - 140 * 4 },
            ease: 'none',
          },
          0
        )

        .to(
          peopleMobileTitle,
          {
            duration: 0.05,
            opacity: 0,
          },
          0.1
        )
        .from(
          [signTitle, signMobileTitle, signParagraphs[1]],
          {
            duration: 0.2,
            opacity: 0,
          },
          0.1
        )
        .to(
          [signTitle, signParagraphs[1]],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+0.5'
        )

        .addLabel('zoomToSmoke', 0.3)
        .from(
          explosionSmokeWrapper,
          {
            duration: 0.2,
            opacity: 0,
          },
          'zoomToSmoke'
        )
        .fromTo(
          explosionSmoke[0],
          {
            opacity: 0,
          },
          {
            duration: 2,
            opacity: 0.6,
            ease: 'power4.out',
          },
          'zoomToSmoke'
        )
        .fromTo(
          explosionSmoke[1],
          {
            opacity: 0.3,
          },
          {
            duration: 1,
            opacity: 0.3,
          },
          'zoomToSmoke'
        )
        .fromTo(
          explosionSmoke[2],
          {
            opacity: 0.4,
          },
          {
            duration: 2,
            opacity: 1,
            ease: 'power4.in',
          },
          'zoomToSmoke'
        )
        .from(
          explosionSmoke[0],
          {
            duration: 2,
            x: -20,
            y: -30,
            scale: 0.01,
            transformOrigin: 'center center',
            ease: 'power2.in',
          },
          'zoomToSmoke'
        )
        .from(
          explosionSmoke[1],
          {
            duration: 2.2,
            y: -30,
            scale: 0.01,
            transformOrigin: 'center center',
            ease: 'power3.in',
          },
          'zoomToSmoke'
        )
        .from(
          explosionSmoke[2],
          {
            duration: 2,
            y: -40,
            scale: 0.015,
            transformOrigin: 'center center',
            ease: 'power3.in',
          },
          'zoomToSmoke'
        )

      // SHOW FINAL SCENE

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#final-scene-trigger',
            scrub: true,
            end: '100% 50%',
            onEnter: () => {
              gsap.set([finalSVG, backSVG], {
                display: 'block',
              })
              gsap.set(storySVG, {
                display: 'none',
              })
              gsap.from(menWrapper, {
                duration: 0.1,
                opacity: 0,
              })
              showText([finalParagraph, finalTitle])
            },
            onEnterBack: () => {
              gsap.set([finalSVG, backSVG], {
                display: 'block',
              })
              gsap.set(storySVG, {
                display: 'none',
              })
              showText([finalParagraph, finalTitle])
            },
            onLeaveBack: () => {
              gsap.set([finalSVG, backSVG], {
                display: 'none',
              })
              gsap.set(storySVG, {
                display: 'block',
              })
              hideText([finalParagraph, finalTitle])
            },
          },
        })
        .to(
          navLine,
          {
            duration: 7,
            attr: { 'stroke-dashoffset': 0 },
            ease: 'none',
          },
          0
        )

        .from(
          menWrapper,
          {
            duration: 0.5,
            scale: 0.5,
            transformOrigin: 'center center',
          },
          0
        )
        .from(
          men,
          {
            duration: 0.5,
            y: 100,
            ease: 'back(2).out',
          },
          0.3
        )

        .to(
          eyes,
          {
            duration: 0.2,
            scaleY: 0.2,
            transformOrigin: 'center bottom',
          },
          1.35
        )
        .to(
          eyes,
          {
            duration: 0.35,
            scaleY: 1,
            transformOrigin: 'center bottom',
          },
          '>+0.1'
        )
        .to(
          mouth,
          {
            duration: 0.2,
            scale: 0.5,
            transformOrigin: 'center bottom',
          },
          1.35
        )
        .to(
          mouth,
          {
            duration: 0.35,
            scale: 1,
            transformOrigin: 'center bottom',
          },
          '>+0.1'
        )
        .to(
          eyebrows[0],
          {
            duration: 0.2,
            rotation: -10,
            transformOrigin: 'right bottom',
            y: 5,
          },
          1.35
        )
        .to(
          eyebrows[0],
          {
            duration: 0.35,
            rotation: 0,
            transformOrigin: 'right bottom',
            y: 0,
          },
          '>+0.1'
        )
        .to(
          eyebrows[1],
          {
            duration: 0.2,
            rotation: 10,
            transformOrigin: 'left bottom',
            y: 5,
          },
          1.35
        )
        .to(
          eyebrows[1],
          {
            duration: 0.35,
            rotation: 0,
            transformOrigin: 'left bottom',
            y: 0,
          },
          '>+0.1'
        )
        .to(
          fullBody,
          {
            duration: 0.2,
            y: -10,
          },
          1.35
        )
        .to(
          fullBody,
          {
            duration: 0.35,
            y: 0,
          },
          '>+0.1'
        )

        .from(
          finalParagraph,
          {
            duration: 0.2,
            opacity: 0,
          },
          '>'
        )
        .to(
          [finalParagraph, signMobileTitle],
          {
            duration: 0.2,
            opacity: 0,
          },
          '>+1'
        )

        .fromTo(
          blackPoints,
          {
            scale: 0.5,
            opacity: 0,
          },
          {
            duration: 0.05,
            stagger: 0.015,
            scale: 1,
            opacity: 1,
            ease: 'back(5).out',
          },
          2
        )
        .from(
          blackPoints[0],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: 650, y: -200 },
                { x: 900, y: 620 },
                { x: 400, y: 320 },
              ],
            },
          },
          2
        )
        .from(
          blackPoints[1],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -350, y: 0 },
                { x: 110, y: -520 },
                { x: -740, y: -300 },
              ],
            },
          },
          2
        )
        .from(
          blackPoints[2],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -150, y: -300 },
                { x: -710, y: -300 },
                { x: -440, y: -400 },
              ],
            },
          },
          2
        )
        .from(
          blackPoints[3],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -50, y: -30 },
                { x: 300, y: 620 },
                { x: 600, y: -500 },
              ],
            },
            ease: 'power4.out',
          },
          2
        )
        .from(
          blackPoints[4],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -100, y: 0 },
                { x: -200, y: 520 },
                { x: 600, y: 400 },
              ],
            },
          },
          2
        )
        .from(
          blackPoints[5],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -100, y: 0 },
                { x: -400, y: 320 },
                { x: -400, y: -200 },
              ],
            },
            ease: 'power4.out',
          },
          2
        )
        .from(
          blackPoints[6],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: 500, y: 200 },
                { x: 600, y: -320 },
                { x: 400, y: -500 },
              ],
            },
          },
          2
        )
        .from(
          blackPoints[7],
          {
            duration: 1,
            motionPath: {
              path: [
                { x: 0, y: 0 },
                { x: -500, y: 0 },
                { x: -300, y: 200 },
                { x: -600, y: 500 },
              ],
            },
            ease: 'power4.out',
          },
          2
        )

        .from(
          lungPoints,
          {
            duration: 0.1,
            stagger: {
              from: 'random',
              amount: 1,
            },
            scale: 0,
            transformOrigin: 'center center',
            ease: 'back(3).out',
          },
          2.2
        )

        .from(
          iconContent,
          {
            duration: 0.1,
            opacity: 0,
          },
          5
        )
        .from(
          iconContent,
          {
            duration: 0.3,
            scale: 0.5,
            transformOrigin: 'center center',
            ease: 'back(1.2).out',
          },
          5
        )
        .to(
          iconBack,
          {
            duration: 0.3,
            attr: { fill: '#e52546' },
          },
          5
        )

        .from(
          backSVG,
          {
            duration: 0.6,
            yPercent: -50,
            ease: 'power2.inOut',
          },
          5.3
        )
        .from(
          finalWagon,
          {
            duration: 0.6,
            y: -1000,
          },
          5.3
        )
        .from(
          finalWagon,
          {
            duration: 0.001,
            opacity: 0,
          },
          5.3
        )
        .to(
          menWrapper,
          {
            duration: 0.6,
            y: 1000,
            ease: 'power1.in',
          },
          5.3
        )
        .to(
          menWrapper,
          {
            duration: 0.05,
            opacity: 0,
            scale: 0.7,
            transformOrigin: 'center center',
          },
          5.85
        )
        .to(
          finalSVG,
          {
            duration: 0.4,
            scale: viewport.ratio > 1 ? 0.75 : 1,
            transformOrigin: 'center top',
            ease: 'power1.inOut',
          },
          6.5
        )

        .from(
          finalTitle,
          {
            duration: 0.2,
            opacity: 0,
          },
          '>'
        )

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#final-frame',
            scrub: true,
            onEnter: () => {
              gsap.from(navIndicatiors[5], {
                duration: 0.3,
                scale: 0.5,
                transformOrigin: 'center center',
                ease: 'back(5).out',
              })
            },
          },
        })
        .to(
          navLabels[4],
          {
            duration: 0.1,
            opacity: 0,
          },
          0
        )
        .to(
          navLabels[5],
          {
            duration: 0.1,
            opacity: 1,
          },
          '>+0.1'
        )
        .to(navIndicatiors[5], {
          duration: 0.3,
          attr: { fill: '#41a42e' },
        })

      gsap.timeline({
        scrollTrigger: {
          trigger: '#final-frame-end',
          scrub: true,
          onEnter: () => {
            $('.dhsv_vc_coal_animation').removeClass('start-animation')
            console.log('#final-frame-end onEnter!') //eslint-disable-line
          },
          onLeaveBack: () => {
            $('.dhsv_vc_coal_animation').addClass('start-animation')
            console.log('#final-frame-end onLeaveBack!') //eslint-disable-line
          },
        },
      })

      setupSmokeText()

      // ----------------------------------------------------------------------------------
      //
      // NAVIGATION BY CLICK

      const triggers = [
        '#start-scene-trigger',
        '#climate-scene-trigger',
        '#environment-scene-trigger',
        '#people-trigger',
        '#health-trigger',
        '#final-frame',
      ]
      navClickable.forEach((c, cIdx) => {
        c.onclick = function () {
          let offset = 0
          if (cIdx === 5) {
            offset = -window.innerHeight
          } else if (cIdx === 3) {
            offset = 0.3 * window.innerHeight
          } else if (cIdx === 2) {
            offset = -0.4 * window.innerHeight
          }
          gsap.to(window, {
            duration: 1,
            scrollTo: { y: triggers[cIdx], offsetY: offset },
          })
        }
      })
    }
  })
}
