import $ from 'jquery'
import { gsap } from 'gsap'
import { TextPlugin } from 'gsap/TextPlugin.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'

gsap.registerPlugin(ScrollTrigger, TextPlugin, ScrollToPlugin)

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(function () {
    let top = $('.dhsv_vc_deforestation_animation').offset().top
    console.log('top', top) //eslint-disable-line
    dhsv_vc_deforestation_animation()
  }, 1000)
})

function dhsv_vc_deforestation_animation() {
  $('.dhsv_vc_deforestation_animation').each(function () {
    // temporary value in pixels
    // should be replaced with the height of elements above the animation (if any)
    const elem = $(this)
    elem.addClass('start-animation')
    const spaceAboveAnimation = 0
    document.querySelector('.el-above-animation').style.height =
      spaceAboveAnimation + 'px'
    document.querySelector('.el-below-animation').style.height =
      spaceAboveAnimation + 'px'
    // spaceAboveAnimation is used as an offset for navigation inside the animation
    // (see setupNavigationBlock() function)

    // -----------------------------------------------------
    // Selectors

    const introText = document.querySelector('.intro-text')
    const storyText = document.querySelector('.story-text')
    const textTitle = document.querySelector('.story-text .subtitle')
    const textBody = document.querySelector('.story-text .body')

    const animation = document.querySelector('#deforestationAnimation')
    const scrollSpace = document.querySelector('.animation-scroll-space')

    const navigationPoints = document.querySelectorAll(
      '.navigation .navigation-point'
    )
    const navigationPointsVisible = document.querySelectorAll(
      '.navigation .navigation-point > *:not(rect)'
    )
    const navigationArrows = document.querySelectorAll(
      '.navigation .navigation-arrow'
    )
    const navigationArrowsVisible = document.querySelectorAll(
      '.navigation .navigation-arrow > polygon'
    )

    const svg = document.querySelector('#animationGraphics')
    const graphics = {
      background: svg.querySelectorAll('.background'),
      river: svg.querySelector('.river'),
      rocks: svg.querySelectorAll('.rocks'),
      gorge: svg.querySelectorAll('.gorge'),
      darkness: svg.querySelectorAll('.dark-scene'),
      darkCoverRect: svg.querySelectorAll('.dark-cover'),
      crossRoads: svg.querySelectorAll('.cross-roads'),
      plants: svg.querySelectorAll('.plants'),
      bananas: svg.querySelectorAll('.bananas'),
      stars: svg.querySelectorAll('.stars > *'),
      eyes: svg.querySelectorAll('.eyes'),
      pupils: svg.querySelectorAll('.pupil'),
      bridgeBoards: svg.querySelectorAll('.bridge-boards > *'),
      bridgeBack: svg.querySelector('.bridge-back'),
      bridgeFront: svg.querySelector('.bridge-front'),

      soil: svg.querySelector('.soil'),
      handPlant: svg.querySelector('.hand-plant'),
      handPlantCopy: svg.querySelector('.hand-plant-copy'),

      banana: svg.querySelector('.hand-banana'),
      basket: svg.querySelector('.basket'),
      basketBananas: svg.querySelectorAll('.basket-bananas > *'),

      crossroadsMen: {
        men: svg.querySelectorAll('.crossroads-men'),
        frontArm: {
          arm: svg.querySelectorAll('.crossroads-men .front-arm'),
          armBottom: svg.querySelectorAll(
            '.crossroads-men .front-arm .arm-bottom'
          ),
          hand: svg.querySelectorAll('.crossroads-men .front-arm .hand'),
        },
        backArm: {
          arm: svg.querySelectorAll('.crossroads-men .back-arm'),
          armBottom: svg.querySelectorAll(
            '.crossroads-men .back-arm .arm-bottom'
          ),
          hand: svg.querySelectorAll('.crossroads-men .back-arm .hand'),
        },
        frontLeg: svg.querySelectorAll('.crossroads-men .front-leg'),
        backLeg: svg.querySelectorAll('.crossroads-men .back-leg'),
      },

      men: {
        men: svg.querySelectorAll('.men'),
        figure: svg.querySelectorAll('.men-wrapper'),
        head: svg.querySelectorAll('.head'),
        headWrapper: svg.querySelectorAll('.head-wrapper'),
        body: svg.querySelectorAll('.body'),
        torch: svg.querySelectorAll('.torch'),
        torchLight: svg.querySelectorAll('.torch-light'),
        plantingTool: svg.querySelectorAll('.plant-tool'),
        finger: svg.querySelectorAll('.finger'),

        frontArm: {
          arm: svg.querySelectorAll('.men .front-arm'),
          armWrapper: svg.querySelectorAll('.men .front-arm .arm-wrapper'),
          armBottom: svg.querySelectorAll('.men .front-arm .arm-bottom'),
          hand: svg.querySelectorAll('.men .front-arm .hand'),
        },
        backArm: {
          arm: svg.querySelectorAll('.men .back-arm'),
          armWrapper: svg.querySelectorAll('.men .back-arm .arm-wrapper'),
          armBottom: svg.querySelectorAll('.men .back-arm .arm-bottom'),
          hand: svg.querySelectorAll('.men .back-arm .hand'),
        },
        frontLeg: {
          leg: svg.querySelectorAll('.men .front-leg'),
          legBottom: svg.querySelectorAll('.men .front-leg .bottom-leg'),
          legBottomWrapper: svg.querySelectorAll(
            '.men .front-leg .bottom-leg-wrapper'
          ),
          feet: svg.querySelectorAll('.men .front-leg .feet'),
          feetWrapper: svg.querySelectorAll('.men .front-leg .feet-wrapper'),
        },
        backLeg: {
          leg: svg.querySelectorAll('.men .back-leg'),
          legBottom: svg.querySelectorAll('.men .back-leg .bottom-leg'),
          legBottomWrapper: svg.querySelectorAll(
            '.men .back-leg .bottom-leg-wrapper'
          ),
          feet: svg.querySelectorAll('.men .back-leg .feet'),
          feetWrapper: svg.querySelectorAll('.men .back-leg .feet-wrapper'),
        },
      },
    }

    // -----------------------------------------------------
    // Globals
    const totalLength = 20000 // walk x-length within SVG coordinate system
    const totalDuration = 10 // total timeline duration
    const numberOfNavPoints = 9 // left-side navigation
    let activePoint = { v: 0 } // store the active section index for navigation

    const textBlocks = [
      {
        title: $(this).data('title-1'),
        body: $(this).data('desc-1'),
      },
      {
        title: $(this).data('title-2'),
        body: $(this).data('desc-2'),
      },
      {
        title: $(this).data('title-3'),
        body: $(this).data('desc-3'),
      },
      {
        title: $(this).data('title-4'),
        body: $(this).data('desc-4'),
      },
      {
        title: $(this).data('title-5'),
        body: $(this).data('desc-5'),
      },
      {
        title: $(this).data('title-6'),
        body: $(this).data('desc-6'),
      },
      {
        title: $(this).data('title-7'),
        body: $(this).data('desc-7'),
      },
      {
        title: $(this).data('title-8'),
        body: $(this).data('desc-8'),
      },
      {
        title: $(this).data('title-9'),
        body: $(this).data('desc-9'),
      },
    ]

    // -----------------------------------------------------
    // -----------------------------------------------------
    // The app itself

    setupLayout()
    window.onresize = setupLayout

    prepareGraphics()
    const walkingTimeline = [getWalkingBodyTimeline(), getWalkingArmsTimeline()]
    prepareScrollTimeline()
    setupNavigationBlock(elem)

    // need it only if the animation placed at the top of the page
    // (position fixed) to set start posture of character
    // gsap.to(window, {
    //     duration: .1,
    //     scrollTo: '+=1'
    // });

    // -----------------------------------------------------
    // -----------------------------------------------------

    function setupLayout() {
      gsap.set(svg, {
        width: window.innerWidth,
        height: window.innerHeight,
      })

      // crop the sides for vertical layout
      // (change ratio if needed)
      if (window.innerWidth / window.innerHeight > 0.8) {
        gsap.set(svg, {
          attr: { viewBox: '0 0 1800 1080' },
        })
      } else if (window.innerWidth / window.innerHeight > 0.5) {
        gsap.set(svg, {
          attr: { viewBox: '200 0 1400 1080' },
        })
      } else {
        gsap.set(svg, {
          attr: { viewBox: '400 0 1000 1080' },
        })
      }
    }

    function setupNavigationBlock(elem) {
      let elemTop = elem.offset().top
      console.log('elemTopttt', elem.offset().top) //eslint-disable-line
      const hoverTransitionDuration = 0.15
      navigationPoints.forEach((np, npIdx) => {
        np.onclick = function () {
          gsap.to(window, {
            duration: 0.5,
            scrollTo:
              elemTop +
              spaceAboveAnimation +
              (npIdx / numberOfNavPoints) * scrollSpace.clientHeight,
          })
        }

        if (npIdx === 0 || npIdx === navigationPoints.length - 1) {
          np.onmouseenter = function () {
            gsap.to(navigationPointsVisible[npIdx], {
              duration: hoverTransitionDuration,
              attr: { fill: '#000000' },
            })
          }
          np.onmouseleave = function () {
            gsap.to(navigationPointsVisible[npIdx], {
              duration: hoverTransitionDuration,
              attr: { fill: '#004187' },
            })
          }
        } else {
          np.onmouseenter = function () {
            gsap.to(navigationPointsVisible[npIdx], {
              duration: hoverTransitionDuration,
              attr: { stroke: '#004187' },
            })
          }
          np.onmouseleave = function () {
            gsap.to(navigationPointsVisible[npIdx], {
              duration: hoverTransitionDuration,
              attr: { stroke: npIdx < 5 ? '#e42444' : '#43b02a' },
            })
          }
        }
      })
      navigationArrows.forEach((a, aIdx) => {
        a.onclick = () => {
          const idx =
            aIdx === 0
              ? Math.floor(activePoint.v - 0.01)
              : Math.ceil(activePoint.v + 0.01)
          gsap.to(window, {
            duration: 0.5,
            scrollTo:
              elemTop +
              spaceAboveAnimation +
              (idx / numberOfNavPoints) * scrollSpace.clientHeight,
          })
        }

        a.onmouseenter = function () {
          gsap.to(navigationArrowsVisible[aIdx], {
            duration: hoverTransitionDuration,
            attr: { fill: '#000000' },
          })
        }
        a.onmouseleave = function () {
          gsap.to(navigationArrowsVisible[aIdx], {
            duration: hoverTransitionDuration,
            attr: { fill: '#004187' },
          })
        }
      })
    }

    function prepareGraphics() {
      gsap.set(graphics.river, {
        x: (1 / (numberOfNavPoints - 2)) * totalLength,
      })
      gsap.set(graphics.rocks, {
        x: (2 / (numberOfNavPoints - 2)) * totalLength,
      })
      gsap.set(graphics.gorge, {
        x: (3 / (numberOfNavPoints - 2)) * totalLength,
      })
      gsap.set(graphics.darkness, {
        x: (4 / (numberOfNavPoints - 2)) * totalLength,
      })
      gsap.set(graphics.crossRoads, {
        x: (5 / (numberOfNavPoints - 2)) * totalLength,
      })
      gsap.set(graphics.plants, {
        x: (6 / (numberOfNavPoints - 2) - 0.01) * totalLength,
      })
      gsap.set(graphics.bananas, {
        x: (7 / (numberOfNavPoints - 2) - 0.012) * totalLength,
      })

      gsap.set(graphics.men.men, {
        x: 850,
        y: 500,
        svgOrigin: '65 320',
      })
      gsap.set(graphics.men.frontLeg.leg, {
        transformOrigin: '50% 5%',
      })
      gsap.set(graphics.men.backLeg.leg, {
        transformOrigin: '50% 5%',
      })
      gsap.set(
        [graphics.men.backLeg.legBottom, graphics.men.backLeg.legBottomWrapper],
        {
          svgOrigin: '81 239',
        }
      )
      gsap.set(
        [
          graphics.men.frontLeg.legBottom,
          graphics.men.frontLeg.legBottomWrapper,
        ],
        {
          svgOrigin: '42 239',
        }
      )
      gsap.set([graphics.men.backLeg.feet, graphics.men.backLeg.feetWrapper], {
        svgOrigin: '83 308',
      })
      gsap.set(
        [graphics.men.frontLeg.feet, graphics.men.frontLeg.feetWrapper],
        {
          svgOrigin: '33 308',
        }
      )
      gsap.set([graphics.men.backArm.arm, graphics.men.backArm.armWrapper], {
        svgOrigin: '75 72',
      })
      gsap.set([graphics.men.frontArm.arm, graphics.men.frontArm.armWrapper], {
        transformOrigin: '50% 5%',
      })
      gsap.set(graphics.men.backArm.armBottom, {
        svgOrigin: '75 123',
      })
      gsap.set(graphics.men.frontArm.armBottom, {
        svgOrigin: '37 125',
      })
      gsap.set([graphics.men.backArm.hand, graphics.handPlant], {
        svgOrigin: '73 172',
      })
      gsap.set(graphics.men.frontArm.hand, {
        svgOrigin: '36 173',
      })
      gsap.set(graphics.men.body, {
        svgOrigin: '55 165',
      })
      gsap.set([graphics.men.head, graphics.men.headWrapper], {
        svgOrigin: '67 47',
      })

      gsap.set(graphics.crossroadsMen.backLeg, {
        svgOrigin: '1273 573',
      })
      gsap.set(graphics.crossroadsMen.frontLeg, {
        svgOrigin: '1282 572',
      })
      gsap.set(graphics.crossroadsMen.backArm.arm, {
        svgOrigin: '1272 535',
      })
      gsap.set(graphics.crossroadsMen.backArm.armBottom, {
        svgOrigin: '1267.5 550',
      })
      gsap.set(graphics.crossroadsMen.frontArm.arm, {
        svgOrigin: '1289 536',
      })
      gsap.set(graphics.crossroadsMen.frontArm.armBottom, {
        svgOrigin: '1290 556',
      })
    }

    function getWalkingSideArmTimeline(arm) {
      return gsap
        .timeline({
          repeat: -1,
        })
        .fromTo(
          graphics.men[arm].arm,
          {
            rotation: 7,
          },
          {
            duration: 0.5,
            rotation: 30,
            ease: 'sine.inOut',
          },
          0
        )
        .to(
          graphics.men[arm].arm,
          {
            duration: 0.5,
            rotation: 7,
            ease: 'sine.inOut',
          },
          0.5
        )

        .set(
          graphics.men[arm].armBottom,
          {
            rotation: -10,
          },
          0
        )
        .to(
          graphics.men[arm].armBottom,
          {
            duration: 0.5,
            rotation: 7,
            ease: 'sine.inOut',
          },
          0
        )
        .to(
          graphics.men[arm].armBottom,
          {
            duration: 0.5,
            rotation: -10,
            ease: 'sine.inOut',
          },
          0.5
        )

        .set(
          graphics.men[arm].hand,
          {
            rotation: -10,
          },
          0
        )
        .to(
          graphics.men[arm].hand,
          {
            duration: 0.5,
            rotation: 0,
            ease: 'sine.inOut',
          },
          0
        )
        .to(
          graphics.men[arm].hand,
          {
            duration: 0.5,
            rotation: -10,
            ease: 'sine.inOut',
          },
          0.5
        )
    }

    function getWalkingSideLegTimeline(leg) {
      return gsap
        .timeline({
          repeat: -1,
        })
        .fromTo(
          graphics.men[leg].leg,
          {
            rotation: -15,
          },
          {
            duration: 0.5,
            rotation: 15,
            ease: 'sine.inOut',
          },
          0
        )
        .to(
          graphics.men[leg].leg,
          {
            duration: 0.25,
            rotation: -15,
            ease: 'sine.in',
          },
          0.5
        )

        .to(
          graphics.men[leg].legBottom,
          {
            duration: 0.25,
            rotation: 15,
            ease: 'sine.inOut',
          },
          0.25
        )
        .to(
          graphics.men[leg].legBottom,
          {
            duration: 0.25,
            rotation: 80,
            ease: 'sine.in',
          },
          0.5
        )
        .to(
          graphics.men[leg].legBottom,
          {
            duration: 0.25,
            rotation: 0,
            ease: 'sine.out',
          },
          0.75
        )

        .to(
          graphics.men[leg].feet,
          {
            duration: 0.25,
            rotation: 40,
            ease: 'sine.out',
          },
          0.5
        )
        .to(
          graphics.men[leg].feet,
          {
            duration: 0.25,
            rotation: 0,
            ease: 'sine.in',
          },
          0.75
        )
    }

    function getWalkingBodyTimeline() {
      return gsap
        .timeline({
          paused: true,
        })
        .add(getWalkingSideLegTimeline('frontLeg'), 0)
        .add(getWalkingSideLegTimeline('backLeg'), 0.5)

        .fromTo(
          graphics.men.figure,
          {
            y: -7,
          },
          {
            duration: 0.25,
            y: 0,
            repeat: -1,
            yoyo: true,
            ease: 'sine.inOut',
          },
          0
        )
        .fromTo(
          graphics.men.head,
          {
            rotation: -2,
          },
          {
            duration: 0.25,
            rotation: 5,
            repeat: -1,
            yoyo: true,
            ease: 'sine.inOut',
          },
          0
        )
    }

    function getWalkingArmsTimeline() {
      return gsap
        .timeline({
          paused: true,
        })
        .add(getWalkingSideArmTimeline('backArm'), 0)
        .add(getWalkingSideArmTimeline('frontArm'), 0.5)
    }

    function prepareScrollTimeline() {
      const tl = gsap.timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: scrollSpace,
          start: '0% 0%',
          end: '100% 100%',
          scrub: true,
          pin: animation,
          //markers: true,
          onEnter: () => {
            // $('.dhsv_vc_deforestation_animation').addClass('start-animation')
          },
          onLeaveBack: () => {
            //  $('.dhsv_vc_deforestation_animation').removeClass('start-animation')
          },
        },
      })

      const armWrapperBaseAngle = -15
      const walkingTimePerSection = [1.5, 8.5]

      for (let i = 0; i < numberOfNavPoints; i++) {
        const sectionDur = totalDuration / numberOfNavPoints

        if (i > 0 && i < numberOfNavPoints - 1) {
          tl.to(
            navigationPointsVisible[i],
            {
              duration: 0.00001,
              attr: { fill: i < 5 ? '#e42444' : '#43b02a' },
            },
            (i * totalDuration) / (numberOfNavPoints - 1) - 0.1
          )
        }
        tl.to(
          activePoint,
          {
            duration: i === numberOfNavPoints - 1 ? 0 : sectionDur,
            v: i + 1,
          },
          (i * totalDuration) / (numberOfNavPoints - 1)
        )

        if (i === 0) {
          tl
            // Start posture
            .fromTo(
              graphics.men.backLeg.legBottomWrapper,
              {
                rotation: -60,
              },
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.backLeg.feetWrapper,
              {
                rotation: -45,
              },
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.men,
              {
                rotation: 3,
              },
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.body,
              {
                rotation: -3,
              },
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.frontArm.armWrapper,
              {
                rotation: 10,
              },
              {
                duration: 0.05 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              0
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 20,
              },
              0
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              0
            )
            .fromTo(
              graphics.men.backArm.arm,
              {
                rotation: -25,
              },
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.backArm.armBottom,
              {
                rotation: 60,
              },
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              graphics.men.head,
              {
                rotation: -7,
              },
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
              },
              0
            )
            .fromTo(
              [
                graphics.men.frontLeg.feetWrapper,
                graphics.men.backLeg.feetWrapper,
              ],
              {
                scaleX: 0.8,
              },
              {
                duration: 0.2 * sectionDur,
                scaleX: 1,
              },
              0
            )

            // Text
            .set(
              textTitle,
              {
                text: textBlocks[0].title,
              },
              0
            )
            .set(
              textBody,
              {
                text: textBlocks[0].body,
              },
              0
            )
            .to(
              introText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              0.3 * sectionDur
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              0.3 * sectionDur
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              0.85 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[1].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[1].body,
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              0.9 * sectionDur
            )

            // Walking
            .to(
              graphics.background,
              {
                duration: 0.95 * sectionDur,
                x: (-1 / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              0.05 * sectionDur
            )
            .fromTo(
              walkingTimeline,
              {
                time: 1.2,
              },
              {
                duration: 0.95 * sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              0.05 * sectionDur
            )
        } else if (i === 1) {
          // River section

          tl
            // Jump
            .to(
              graphics.men.men,
              {
                duration: 0.03 * sectionDur,
                x: '+=40',
                y: '+=100',
              },
              i * sectionDur + 0.06 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.2 * sectionDur,
                y: '-=65',
                x: '-=40',
                ease: 'none',
              },
              '>'
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: armWrapperBaseAngle - 80,
              },
              i * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle + 30,
              },
              i * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.2 * sectionDur
            )

            // Text
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.7 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[2].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[2].body,
              },
              '>'
            )

            // walking
            .to(
              graphics.background,
              {
                duration: sectionDur,
                x: (-(i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline,
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )
        } else if (i === 2) {
          // Rocks section

          tl

            // bend
            .to(
              graphics.men.men,
              {
                duration: 0.15 * sectionDur,
                y: '+=80',
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.1 * sectionDur,
                rotation: 10,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              graphics.men.body,
              {
                duration: 0.1 * sectionDur,
                rotation: 25,
              },
              i * sectionDur + 0.15 * sectionDur
            )
            .to(
              graphics.men.headWrapper,
              {
                duration: 0.15 * sectionDur,
                rotation: -30,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: -60,
              },
              i * sectionDur + 0.15 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              '>'
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: 5,
              },
              i * sectionDur + 0.15 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              '>'
            )

            // back
            .to(
              graphics.men.men,
              {
                duration: 0.6 * sectionDur,
                y: '-=50',
                ease: 'power1.in',
              },
              i * sectionDur + 0.35 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.35 * sectionDur
            )
            .to(
              graphics.men.body,
              {
                duration: 0.2 * sectionDur,
                rotation: 0,
                ease: 'power1.in',
              },
              i * sectionDur + 0.35 * sectionDur
            )
            .to(
              graphics.men.headWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.35 * sectionDur
            )

            // text
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              i * sectionDur
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.7 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[3].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[3].body,
              },
              '>'
            )

            // walking (w/ hands not moving in the middle of the scene)
            .to(
              graphics.background,
              {
                duration: sectionDur,
                x: (-(i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline[0],
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline[1],
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: 0.3 * sectionDur,
                time: walkingTimePerSection[1] - 4.5,
                ease: 'none',
              },
              i * sectionDur
            )
            .to(
              walkingTimeline[1],
              {
                duration: 0.55 * sectionDur,
                time: walkingTimePerSection[1] - 1,
                ease: 'none',
              },
              i * sectionDur + 0.45 * sectionDur
            )
        } else if (i === 3) {
          // Bridge section

          tl.to(
            storyText,
            {
              duration: 0.1 * sectionDur,
              opacity: 1,
            },
            i * sectionDur
          )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.7 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[4].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[4].body,
              },
              '>'
            )

            // bridge to be a bit destroyed
            .to(
              graphics.bridgeBoards[1],
              {
                duration: 0.08 * sectionDur,
                x: -5,
                y: 5,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.bridgeBoards[4],
              {
                duration: 0.08 * sectionDur,
                x: 5,
                y: 5,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.4 * sectionDur
            )
            .to(
              graphics.bridgeFront,
              {
                duration: 0.08 * sectionDur,
                skewY: 2,
                transformOrigin: 'left bottom',
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.bridgeBack,
              {
                duration: 0.08 * sectionDur,
                skewY: -2,
                transformOrigin: 'left bottom',
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.34 * sectionDur
            )

            // walking with going down along the bridge steps
            .to(
              graphics.men.men,
              {
                duration: 0.08 * sectionDur,
                y: '+=40',
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.12 * sectionDur,
                y: '-=40',
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.background,
              {
                duration: sectionDur,
                x: (-(i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline,
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )

            // spread the arms a bit and bend back while crossing
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.08 * sectionDur,
                rotation: -60,
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.12 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              '>'
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.08 * sectionDur,
                rotation: 30,
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.12 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              '>'
            )
            .to(
              graphics.men.body,
              {
                duration: 0.08 * sectionDur,
                rotation: -5,
              },
              i * sectionDur + 0.34 * sectionDur
            )
            .to(
              graphics.men.body,
              {
                duration: 0.12 * sectionDur,
                rotation: 0,
              },
              '>'
            )
        } else if (i === 4) {
          // Dark section

          tl
            // light up the stars
            .from(
              graphics.stars,
              {
                duration: 0.02 * sectionDur,
                stagger: {
                  from: 'random',
                  amount: 0.1 * sectionDur,
                },
                opacity: 0,
              },
              i * sectionDur - 0.2 * sectionDur
            )
            .to(
              graphics.darkCoverRect,
              {
                duration: 0.2 * sectionDur,
                attr: { opacity: 0.6 },
              },
              i * sectionDur - 0.2 * sectionDur
            )

            // lift the arm and turn the light
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: armWrapperBaseAngle + 25,
              },
              i * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: armWrapperBaseAngle - 30,
              },
              i * sectionDur
            )
            .to(
              graphics.men.backArm.armBottom,
              {
                duration: 0.2 * sectionDur,
                rotation: -25,
              },
              i * sectionDur
            )
            .to(
              graphics.men.backArm.hand,
              {
                duration: 0.2 * sectionDur,
                rotation: -40,
              },
              i * sectionDur
            )
            .fromTo(
              [graphics.men.torch, graphics.men.finger],
              {
                opacity: 0,
              },
              {
                duration: 0.02 * sectionDur,
                opacity: 1,
              },
              i * sectionDur + 0.09 * sectionDur
            )
            .from(
              graphics.men.torchLight,
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.12 * sectionDur
            )

            // make the eyes appear but not to overlap with figure
            .from(
              graphics.eyes[4],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.4 * sectionDur
            )
            .from(
              graphics.eyes[1],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.45 * sectionDur
            )
            .to(
              graphics.eyes[2],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .from(
              graphics.eyes[0],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .from(
              graphics.eyes[3],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.eyes,
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.9 * sectionDur
            )

            // make eyes look at the moving figure
            .fromTo(
              graphics.pupils[0],
              {
                rotation: -40,
                svgOrigin: '1132 1007',
              },
              {
                duration: 0.5 * sectionDur,
                rotation: 60,
              },
              i * sectionDur + 0.3 * sectionDur
            )
            .fromTo(
              graphics.pupils[1],
              {
                rotation: -40,
                svgOrigin: '1156 1007',
              },
              {
                duration: 0.5 * sectionDur,
                rotation: 60,
              },
              i * sectionDur + 0.3 * sectionDur
            )
            .fromTo(
              graphics.pupils[6],
              {
                rotation: 0,
                svgOrigin: '1317 472',
              },
              {
                duration: 0.3 * sectionDur,
                rotation: -120,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .fromTo(
              graphics.pupils[7],
              {
                rotation: 0,
                svgOrigin: '1331 472',
              },
              {
                duration: 0.3 * sectionDur,
                rotation: -120,
              },
              i * sectionDur + 0.5 * sectionDur
            )

            // back to the day
            .to(
              graphics.darkCoverRect,
              {
                duration: 0.1 * sectionDur,
                attr: { opacity: 0 },
              },
              i * sectionDur + 0.9 * sectionDur
            )
            .to(
              [graphics.men.backArm.armBottom, graphics.men.backArm.hand],
              {
                duration: 0.2 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.8 * sectionDur
            )
            .to(
              [graphics.men.torch, graphics.men.finger],
              {
                duration: 0.02 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.93 * sectionDur
            )
            .to(
              graphics.men.torchLight,
              {
                duration: 0.02 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.83 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.8 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.8 * sectionDur
            )

            // text
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              i * sectionDur
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.7 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[5].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[5].body,
              },
              '>'
            )
            .set(
              textTitle,
              {
                duration: 0.01 * sectionDur,
                color: '#43b02a',
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              i * sectionDur + 0.85 * sectionDur
            )

            // walking
            .to(
              graphics.background,
              {
                duration: sectionDur,
                x: (-(i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline[0],
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )
        } else if (i === 5) {
          // Crossroads section

          tl
            // text
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .set(
              textTitle,
              {
                text: textBlocks[6].title,
              },
              '>'
            )
            .set(
              textBody,
              {
                text: textBlocks[6].body,
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              i * sectionDur + 0.3 * sectionDur
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.9 * sectionDur
            )

            // walking
            .to(
              graphics.background,
              {
                duration: sectionDur,
                x: (-(i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline,
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: sectionDur,
                time: walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )

            // man to get closer
            .fromTo(
              graphics.crossroadsMen.men,
              {
                x: -120,
                y: 60,
                scale: 1.5,
                transformOrigin: 'center center',
              },
              {
                duration: 0.2 * sectionDur,
                x: -160,
                y: 75,
                scale: 2,
                ease: 'power3.in',
              },
              i * sectionDur + 0.3 * sectionDur
            )

            // men to raise the hands
            .to(
              graphics.crossroadsMen.frontArm.arm,
              {
                duration: 0.1 * sectionDur,
                rotation: -20,
              },
              i * sectionDur - 0.2 * sectionDur
            )
            .to(
              graphics.crossroadsMen.frontArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: -20,
              },
              i * sectionDur - 0.2 * sectionDur
            )
            .to(
              graphics.crossroadsMen.frontArm.arm,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.crossroadsMen.frontArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .fromTo(
              graphics.crossroadsMen.backArm.arm,
              {
                rotation: -20,
              },
              {
                duration: 0.15 * sectionDur,
                rotation: 20,
              },
              i * sectionDur + 0.45 * sectionDur
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: -125,
              },
              i * sectionDur - 0.2 * sectionDur
            )

            // men to wave
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.27 * sectionDur,
                rotation: 40,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.4 * sectionDur
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 40,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 40,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 40,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 40,
                ease: 'power1.inOut',
              },
              '>'
            )

            // men fake and ugly walk
            .to(
              graphics.crossroadsMen.frontLeg,
              {
                duration: 0.05 * sectionDur,
                rotation: -15,
                scale: 0.9,
                x: -2,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.35 * sectionDur
            )
            .to(
              graphics.crossroadsMen.frontLeg,
              {
                duration: 0.05 * sectionDur,
                rotation: 5,
                scale: 1.1,
                x: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.frontLeg,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                scale: 1,
                x: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              graphics.crossroadsMen.backLeg,
              {
                duration: 0.05 * sectionDur,
                rotation: 5,
                scale: 1.02,
                x: 0,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.4 * sectionDur
            )
            .to(
              graphics.crossroadsMen.backLeg,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
                scale: 1,
                x: 0,
                ease: 'power1.inOut',
              },
              '>'
            )
        } else if (i === 6) {
          // Plants section

          tl
            // text
            .set(
              textTitle,
              {
                text: textBlocks[7].title,
              },
              i * sectionDur + 0.4 * sectionDur
            )
            .set(
              textBody,
              {
                text: textBlocks[7].body,
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + sectionDur
            )

            // walking
            .to(
              graphics.background,
              {
                duration: 0.68 * sectionDur,
                x: (-(i + 0.7) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur
            )
            .fromTo(
              walkingTimeline,
              {
                time: walkingTimePerSection[0],
              },
              {
                duration: 0.7 * sectionDur,
                time: 0.67 * walkingTimePerSection[1],
                ease: 'none',
              },
              i * sectionDur
            )

            // sit down
            .to(
              graphics.men.men,
              {
                duration: 0.07 * sectionDur,
                y: '+=45',
              },
              i * sectionDur + 0.65 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.15 * sectionDur,
                rotation: -35,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .to(
              graphics.men.backLeg.legBottomWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: 130,
              },
              i * sectionDur + 0.55 * sectionDur
            )
            .to(
              graphics.men.frontLeg.legBottomWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: 80,
              },
              i * sectionDur + 0.65 * sectionDur
            )
            .to(
              graphics.men.body,
              {
                duration: 0.35 * sectionDur,
                rotation: 70,
                x: -7,
                y: 12,
              },
              i * sectionDur + 0.6 * sectionDur
            )

            // put the plant in the soil
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.25 * sectionDur,
                rotation: -40,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.25 * sectionDur,
                rotation: -50,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.25 * sectionDur,
                rotation: -45,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .to(
              graphics.men.backArm.armBottom,
              {
                duration: 0.25 * sectionDur,
                rotation: -35,
              },
              i * sectionDur + 0.6 * sectionDur
            )

            // show tool and plant
            .fromTo(
              [graphics.men.plantingTool, graphics.men.finger],
              {
                opacity: 0,
              },
              {
                duration: 0.02 * sectionDur,
                opacity: 1,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .from(
              graphics.handPlant,
              {
                duration: 0.02 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.6 * sectionDur
            )
            .from(
              graphics.handPlant,
              {
                duration: 0.02 * sectionDur,
                y: -10,
              },
              i * sectionDur + 0.6 * sectionDur
            )

            // Put plant
            .to(
              graphics.men.backArm.hand,
              {
                duration: 0.2 * sectionDur,
                rotation: -80,
              },
              i * sectionDur + 0.8 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: -45,
                x: 10,
                y: 10,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.85 * sectionDur
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: -30,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.85 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: -50,
                scale: 1,
                x: 0,
                y: 0,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.95 * sectionDur
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: -40,
                ease: 'power1.inOut',
              },
              i * sectionDur + 0.95 * sectionDur
            )

            .to(
              graphics.men.backArm.hand,
              {
                duration: 0.1 * sectionDur,
                x: -10,
              },
              i * sectionDur + 0.95 * sectionDur
            )
            .to(
              graphics.men.backArm.hand,
              {
                duration: 0.2 * sectionDur,
                x: 0,
                rotation: 0,
              },
              '>'
            )

            // hide tool and replace plant
            .to(
              graphics.men.plantingTool,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.95 * sectionDur
            )
            .to(
              [graphics.handPlant, graphics.men.finger],
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + sectionDur
            )
            .from(
              graphics.handPlantCopy,
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.99 * sectionDur
            )
        } else if (i === 7) {
          // Banana section

          tl.set(
            textTitle,
            {
              text: textBlocks[8].title,
            },
            i * sectionDur + 0.2 * sectionDur
          )
            .set(
              textBody,
              {
                text: textBlocks[8].body,
              },
              '>'
            )
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 1,
              },
              '>'
            )

            // Get up (body)
            .to(
              graphics.men.men,
              {
                duration: 0.1 * sectionDur,
                rotation: 5,
                x: '-=100',
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.1 * sectionDur,
                y: '-=45',
                ease: 'back(3).out',
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.body,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
                x: 0,
                y: 0,
                ease: 'power1.in',
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.2 * sectionDur
            )

            // Get up (legs)
            .to(
              graphics.men.backLeg.legBottomWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.frontLeg.legBottomWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              [
                graphics.men.frontLeg.feetWrapper,
                graphics.men.backLeg.feetWrapper,
              ],
              {
                duration: 0.05 * sectionDur,
                rotation: -10,
              },
              i * sectionDur + 0.15 * sectionDur
            )

            // Get up (arms)
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.05 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.05 * sectionDur,
                rotation: armWrapperBaseAngle,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.backArm.armBottom,
              {
                duration: 0.05 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.1 * sectionDur
            )

            // continue walking (plants to banana tree)
            .to(
              graphics.background,
              {
                duration: 0.83 * sectionDur,
                x: -((i + 1) / (numberOfNavPoints - 1)) * totalLength,
                ease: 'none',
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              walkingTimeline,
              {
                duration: 0.83 * sectionDur,
                time: walkingTimePerSection[1] + 6.18,
                ease: 'none',
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              graphics.men.backLeg.legBottomWrapper,
              {
                duration: 0.02 * sectionDur,
                rotation: 0,
              },
              i * sectionDur + 0.2 * sectionDur
            )
        } else if (i === 8) {
          // Final section

          tl
            // Text
            .to(
              storyText,
              {
                duration: 0.1 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.9 * sectionDur
            )

            // Stand next to the tree
            .to(
              graphics.men.frontLeg.legBottomWrapper,
              {
                duration: 0.05 * sectionDur,
                rotation: -50,
              },
              i * sectionDur
            )
            .to(
              graphics.men.backLeg.legBottomWrapper,
              {
                duration: 0.02 * sectionDur,
                rotation: 10,
              },
              i * sectionDur
            )
            .to(
              graphics.men.frontLeg.feetWrapper,
              {
                duration: 0.05 * sectionDur,
                rotation: -35,
              },
              i * sectionDur
            )

            // pick the banana
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.17 * sectionDur,
                rotation: -120,
                ease: 'back(1.2).in',
              },
              i * sectionDur + 0.03 * sectionDur
            )
            .to(
              graphics.men.frontArm.armBottom,
              {
                duration: 0.15 * sectionDur,
                rotation: -65,
              },
              i * sectionDur - 0.05 * sectionDur
            )
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.1 * sectionDur,
                rotation: -30,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .to(
              graphics.men.backArm.armBottom,
              {
                duration: 0.1 * sectionDur,
                rotation: -30,
              },
              i * sectionDur + 0.1 * sectionDur
            )
            .from(
              graphics.banana,
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.16 * sectionDur
            )

            // take the basket
            .from(
              graphics.basket,
              {
                duration: 0.01 * sectionDur,
                opacity: 0,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .fromTo(
              graphics.basket,
              {
                x: 85,
                y: 88,
                rotation: 3,
                transformOrigin: 'center bottom',
              },
              {
                duration: 0.05 * sectionDur,
                x: 85,
                y: 85,
                rotation: 4,
              },
              i * sectionDur + 0.2 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: -30,
              },
              i * sectionDur + 0.3 * sectionDur
            )

            // Bring basket up
            .to(
              graphics.men.backArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: -55,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.men.backArm.armBottom,
              {
                duration: 0.2 * sectionDur,
                rotation: 60,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.men.frontArm.armWrapper,
              {
                duration: 0.2 * sectionDur,
                rotation: 20,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.men.backLeg.legBottom,
              {
                duration: 0.2 * sectionDur,
                rotation: 10,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.men.frontLeg.legBottom,
              {
                duration: 0.2 * sectionDur,
                rotation: 50,
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.men.men,
              {
                duration: 0.2 * sectionDur,
                x: '-=20',
              },
              i * sectionDur + 0.5 * sectionDur
            )
            .to(
              graphics.basket,
              {
                duration: 0.2 * sectionDur,
                x: 0,
                y: 10,
                rotation: 10,
              },
              i * sectionDur + 0.5 * sectionDur
            )

            // show the bananas in the basket
            .from(
              graphics.basketBananas,
              {
                duration: 0.1 * sectionDur,
                stagger: 0.03 * sectionDur,
                x: 3,
                y: 25,
                ease: 'back.out(2)',
              },
              i * sectionDur + 0.75 * sectionDur
            )
        }
      }

      return tl
    }
  })
}
