import $ from 'jquery'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, ScrollToPlugin)

$(document).ready(function () {
  dhsv_vc_elevator_animation()
})

function dhsv_vc_elevator_animation() {
  $('.dhsv_vc_elevator_animation').each(function () {
    let assetSize = {
      w: 0,
      h: 0,
    }

    const fakeHeightFullScreenEls = document.querySelectorAll(
      '.fake-height--full-screen'
    )
    const fakeHeightEls = Array.from(document.querySelectorAll('.fake-height'))

    const container = document.querySelector('.story-container')
    const storySVG = container.querySelector('#story')
    const svgWrapper = storySVG.querySelector('.wrapper')

    const navbar = document.querySelector('.navbar')
    const ground = document.querySelector('.ground')
    const header = document.querySelector('.header')
    const floorTexts = Array.from(
      document.querySelectorAll('.floor-texts > div')
    )
    const floorTextLines = Array.from(
      document.querySelectorAll('.floor-texts > div > div')
    )
    const floorTextTitleLines = Array.from(
      document.querySelectorAll('.floor-texts > div > div.title')
    )

    const floorTriggers = Array.from(
      document.querySelectorAll('.floor-trigger')
    )

    const city = Array.from(storySVG.querySelectorAll('.city'))
    const elevatorShaft = storySVG.querySelector('.elevator-shaft')
    const elevatorShaftLine = storySVG.querySelector('.elevator-shaft-line')
    const floorScenes = Array.from(storySVG.querySelectorAll('.floor-scene'))
    const floorBacks = Array.from(storySVG.querySelectorAll('.floor-back'))
    const floorWalls = Array.from(storySVG.querySelectorAll('.floor-walls'))
    const floorBacksMobileOverlay = storySVG.querySelector(
      '.floor-back-mobile-overlay'
    )
    const cabin = storySVG.querySelector('.cabin')
    const cabinBack = cabin.querySelector('.cabin-back')
    const man = cabin.querySelector('.man')

    const navbarEls = {
      startText: navbar.querySelector('.start-text'),
      startTextInteractive: navbar.querySelector('.start-text .clickable'),
      arrows: Array.from(navbar.querySelectorAll('.arrow .clickable')),
      pointsInteractive: Array.from(
        navbar.querySelectorAll('.floor-point .clickable')
      ),
      pointsVisible: Array.from(
        navbar.querySelectorAll('.floor-point .circle')
      ),
    }

    const manEls = {
      leftLeg: man.querySelector('.leg-left'),
      shadow: storySVG.querySelector('.man-shadow'),
      leftArm: man.querySelector('.arm-left'),
    }
    const tenthFloorEls = {
      sign: floorScenes[0].querySelector('.sign'),
      flag: floorScenes[0].querySelector('.flag'),
      eagle: floorScenes[0].querySelector('.eagle'),
    }
    const ninthFloorEls = {
      girl: floorScenes[1].querySelector('.girl'),
      envelope: floorScenes[1].querySelector('.envelope'),
      box: floorScenes[1].querySelector('.box'),
      arm: floorScenes[1].querySelector('.arm'),
      armTop: floorScenes[1].querySelector('.arm-top'),
      armBottom: floorScenes[1].querySelectorAll('.arm-bottom'),
    }
    const eighthFloorEls = {
      buildings: Array.from(floorScenes[2].querySelectorAll('.building')),
      man: floorScenes[2].querySelector('.man'),
      girl: floorScenes[2].querySelector('.girl'),
      bubbles: Array.from(floorScenes[2].querySelectorAll('.bubble')),
      bubbleTexts: Array.from(floorScenes[2].querySelectorAll('.bubble-text')),
    }
    const seventhFloorEls = {
      people: Array.from(floorScenes[3].querySelectorAll('.person')),
      bubbles: Array.from(floorScenes[3].querySelectorAll('.bubble')),
      bubbleTexts: Array.from(floorScenes[3].querySelectorAll('.bubble-text')),
    }
    const sixthFloorEls = {
      bubbles: Array.from(floorScenes[4].querySelectorAll('.bubble')),
      bubbleTexts: Array.from(floorScenes[4].querySelectorAll('.bubble-text')),
    }
    const fifthFloorEls = {
      papers: Array.from(floorScenes[5].querySelectorAll('.paper')),
      sign: floorScenes[5].querySelector('.sign'),
      pen: floorScenes[5].querySelector('.pen'),
    }
    const fourthFloorEls = {
      table: floorScenes[6].querySelector('.table'),
      man: floorScenes[6].querySelector('.man'),
      nameplate: floorScenes[6].querySelector('.nameplate'),
      mics: Array.from(floorScenes[6].querySelectorAll('.mic')),
      mouth: floorScenes[6].querySelector('.mouth'),
    }
    const thirdFloorEls = {
      people: Array.from(floorScenes[7].querySelectorAll('.person')),
      desk: floorScenes[7].querySelector('.desk'),
      speaker: floorScenes[7].querySelector('.speaker'),
      hand: floorScenes[7].querySelector('.speaker-hand'),
    }
    const secondFloorEls = {
      people: Array.from(floorScenes[8].querySelectorAll('.person')),
      signs: Array.from(floorScenes[8].querySelectorAll('.sign')),
      hands: Array.from(floorScenes[8].querySelectorAll('.hand')),
    }
    const firstFloorEls = {
      sign: floorScenes[9].querySelector('.sign'),
      base: floorScenes[9].querySelector('.base'),
      hammer: floorScenes[9].querySelector('.hammer'),
    }
    const groundFloorEls = {
      papers: Array.from(floorScenes[10].querySelectorAll('.paper')),
      signCircle: floorScenes[10].querySelector('.sign-circle'),
      signLine: floorScenes[10].querySelector('.sign-line'),
    }

    let startTimeline

    createScrollAnimation()
    adjustLayout()
    setupNavbar()
    gsap.set(header, {
      opacity: 1,
    })
    window.onresize = function () {
      adjustLayout()
      setupNavbar()
    }

    function setupNavbar() {
      navbarEls.pointsInteractive.forEach((p, pIdx) => {
        p.onclick = function () {
          if (pIdx) {
            gsap.to(window, {
              duration: 0.5,
              scrollTo: {
                y: floorTriggers[pIdx - 1],
                offsetY: 0.5 * fakeHeightEls[0].clientHeight,
              },
            })
          } else {
            gsap.to(window, {
              duration: 0.5,
              scrollTo: {
                y: floorTriggers[0],
                offsetY: 1.5 * fakeHeightEls[0].clientHeight,
              },
            })
          }
        }
      })
      navbarEls.startTextInteractive.onclick = function () {
        gsap.to(window, {
          duration: 0.25,
          scrollTo: { y: 0 },
        })
      }
      navbarEls.arrows[0].onclick = function () {
        gsap.to(window, {
          duration: 0.25,
          scrollTo: { y: '-=' + fakeHeightEls[0].clientHeight },
        })
      }
      navbarEls.arrows[1].onclick = function () {
        gsap.to(window, {
          duration: 0.25,
          scrollTo: { y: '+=' + fakeHeightEls[0].clientHeight },
        })
      }
    }

    function adjustLayout() {
      assetSize = {
        w: window.innerWidth,
        h: window.innerHeight,
      }
      gsap.set(fakeHeightFullScreenEls, {
        height: window.innerHeight,
      })
      gsap.set(fakeHeightEls, {
        height: 0.8 * assetSize.h,
      })
      navbarEls.pointsVisible.forEach(p => {
        p.classList.remove('active')
      })

      if (assetSize.w / assetSize.h > 1.2) {
        gsap.set(container, {
          width: assetSize.w,
        })
        gsap.set(navbar, {
          left: 'auto',
          right: 3,
        })
        gsap.set([container, navbar], {
          height: assetSize.h,
        })
        const floorFontSize = 0.022 * assetSize.h
        gsap.set(storySVG, {
          width: 'auto',
          height: 3.2 * assetSize.h,
          onComplete: () => {
            gsap.set(header, {
              top: 0,
              left: 0,
              xPercent: 0,
              bottom: 'auto',
              height: 0.425 * assetSize.h,
              fontSize: 1.15 * floorFontSize,
            })
            floorTexts.forEach((t, tIdx) => {
              gsap.set(t, {
                bottom: 'auto',
                xPercent: 0,
                width: 0.32 * assetSize.h,
                height: 0.2 * assetSize.h,
                fontSize: floorFontSize,
                justifyContent: 'center',
              })
              if (tIdx % 2) {
                gsap.set(t, {
                  left:
                    0.5 * assetSize.w - 0.13 * assetSize.h - 0.36 * assetSize.h,
                })
              } else {
                gsap.set(t, {
                  left: 0.5 * assetSize.w + 0.13 * assetSize.h,
                })
              }
              if (tIdx !== 10) {
                gsap.set(t, {
                  top: 0.38 * assetSize.h,
                })
              } else {
                gsap.set(t, {
                  top: 0.5 * assetSize.h,
                })
              }
            })
            gsap.set(floorTextLines, {
              paddingLeft: 0.02 * assetSize.h,
              paddingBottom: 0.01 * assetSize.h,
            })
            gsap.set(floorTextTitleLines, {
              paddingTop: 0.02 * assetSize.h,
            })
          },
        })
        gsap.set(ground, {
          height: 0.28 * assetSize.h,
        })

        gsap.set(floorBacksMobileOverlay, {
          opacity: 0,
        })
      } else {
        gsap.set(container, {
          width: assetSize.w - 80,
        })
        gsap.set(navbar, {
          left: 0,
          right: 'auto',
        })
        gsap.set([container, navbar], {
          height: 0.7 * assetSize.h,
        })
        const floorFontSize = 0.02 * assetSize.h
        gsap.set(storySVG, {
          width: 7.8 * assetSize.w,
          height: 'auto',
          onComplete: () => {
            gsap.set([header, floorTexts], {
              top: 'auto',
              left: '50%',
              xPercent: -50,
              bottom: 15,
              height: assetSize.h - storySVG.getBoundingClientRect().bottom,
              fontSize: floorFontSize,
            })
            gsap.set(floorTexts, {
              justifyContent: 'flex-start',
            })

            if (startTimeline) {
              if (startTimeline.progress() > 0.2) {
                gsap.set(floorTexts, {
                  width: floorBacksMobileOverlay.getBoundingClientRect().width,
                })
              }
            }
            gsap.set(floorTextLines, {
              paddingLeft: 0,
              paddingBottom: 6,
            })
            gsap.set(floorTextTitleLines, {
              paddingTop: 25,
            })
          },
        })
        gsap.set(ground, {
          height: assetSize.w * 0.3,
        })
        gsap.set(floorBacksMobileOverlay, {
          opacity: 1,
        })
      }
    }

    function createScrollAnimation() {
      // ------------------------------------------------
      //
      // Zoom to the 10th floor and show the top scene

      gsap.timeline({
        scrollTrigger: {
          trigger: '#show-trigger',
          scrub: true,
          onEnter: () => {
            $('.dhsv_vc_elevator_animation').addClass('start-animation')
          },
          onLeaveBack: () => {
            $('.dhsv_vc_elevator_animation').removeClass('start-animation')
          },
        },
      })

      startTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: '#zoom-trigger',
            end: '100% 100%',
            scrub: true,
            onUpdate: self => {
              if (self.progress > 0.33 && self.progress < 0.99) {
                navbarEls.pointsVisible[0].classList.add('active')
              } else {
                navbarEls.pointsVisible[0].classList.remove('active')
              }
              if (self.progress > 0.2) {
                if (assetSize.w / assetSize.h <= 1.2) {
                  gsap.set(floorTexts, {
                    width: floorBacksMobileOverlay.getBoundingClientRect()
                      .width,
                  })
                }
              }
            },
          },
        })
        .fromTo(
          svgWrapper,
          {
            y: 0,
            scale: 0.2,
            transformOrigin: 'center bottom',
          },
          {
            y: 950,
            scale: 1,
            transformOrigin: 'center bottom',
          }
        )
        .fromTo(
          ground,
          {
            yPercent: 0,
            scaleY: 0.45,
            transformOrigin: 'center bottom',
          },
          {
            yPercent: 110,
            scaleY: 1,
            transformOrigin: 'center bottom',
            ease: 'power2.in',
          },
          0
        )
        .fromTo(
          city[0],
          {
            scale: 1,
            transformOrigin: 'center bottom',
          },
          {
            scale: 2.5,
            transformOrigin: 'center bottom',
          },
          0
        )
        .fromTo(
          city[1],
          {
            scale: 1,
            transformOrigin: 'center bottom',
          },
          {
            scale: 3.5,
            transformOrigin: 'center bottom',
          },
          0
        )

        .to(
          elevatorShaftLine,
          {
            duration: 0.2,
            attr: { y1: 112 },
            ease: 'power2.in',
          },
          0
        )
        .to(
          elevatorShaft,
          {
            attr: {
              fill: '#466daf',
            },
          },
          0
        )
        .to(
          floorBacks,
          {
            attr: {
              fill: '#99ABD0',
              stroke: '#466DAF',
            },
          },
          0
        )
        .to(
          cabinBack,
          {
            duration: 0.8,
            opacity: 1,
            ease: 'power2.out',
          },
          0.2
        )
        .fromTo(
          man,
          {
            opacity: 0,
            y: -6,
          },
          {
            duration: 0.5,
            opacity: 1,
            y: 0,
          },
          0.5
        )
        .to(
          manEls.shadow,
          {
            duration: 0.5,
            opacity: 1,
          },
          0.5
        )
        .from(
          manEls.leftLeg,
          {
            duration: 0.5,
            rotation: -10,
            transformOrigin: 'center top',
          },
          0.5
        )
        .from(
          manEls.leftArm,
          {
            duration: 0.5,
            rotation: -10,
            transformOrigin: 'center top',
          },
          0.5
        )

        .to(
          header,
          {
            duration: 0.3,
            opacity: 0,
          },
          0.5
        )

        .set(
          elevatorShaftLine,
          {
            attr: { y1: 14 },
          },
          0.7
        )

      showFloor(startTimeline, 0, 1)

      startTimeline
        .from(
          tenthFloorEls.sign,
          {
            duration: 0.2,
            opacity: 0,
          },
          '>'
        )
        .from(
          tenthFloorEls.flag,
          {
            duration: 0.2,
            x: 10,
            opacity: 0,
          },
          '>-0.1'
        )
        .from(
          tenthFloorEls.eagle,
          {
            duration: 0.3,
            scale: 0.85,
            transformOrigin: 'center bottom',
            opacity: 0,
          },
          '>-0.1'
        )

        .from(
          floorTexts[0],
          {
            duration: 0.15,
            opacity: 0,
          },
          1.7
        )
      hideFloor(startTimeline, 0, 2.8)

      // ------------------------------------------------
      //
      // Show scenes 9th to 1st

      navbarEls.pointsVisible.forEach((p, pIdx) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: floorTriggers[pIdx],
            end: '100% 100%',
            scrub: true,
            onEnter: () => {
              p.classList.remove('active')
              if (pIdx + 1 != navbarEls.pointsVisible.length) {
                navbarEls.pointsVisible[pIdx + 1].classList.add('active')
              }
            },
            onEnterBack: () => {
              p.classList.remove('active')
              if (pIdx + 1 != navbarEls.pointsVisible.length) {
                navbarEls.pointsVisible[pIdx + 1].classList.add('active')
              }
            },
            onLeave: () => {
              if (pIdx + 1 != navbarEls.pointsVisible.length) {
                navbarEls.pointsVisible[pIdx + 1].classList.remove('active')
              }
            },
            onLeaveBack: () => {
              if (pIdx + 1 != navbarEls.pointsVisible.length) {
                navbarEls.pointsVisible[pIdx + 1].classList.remove('active')
              }
            },
          },
        })
      })

      let i

      for (i = 0; i < navbarEls.pointsVisible.length - 1; i++) {
        const tl = gsap
          .timeline({
            scrollTrigger: {
              trigger: floorTriggers[i],
              end: '100% 100%',
              scrub: true,
            },
          })
          .to(cabin, {
            duration: 0.3,
            y: '+=97.9',
            ease: 'power1.inOut',
          })
          .to(
            svgWrapper,
            {
              duration: 0.3,
              y: i === 9 ? '-=50' : '-=97.9',
              ease: 'power1.inOut',
            },
            0
          )

        showFloor(tl, i + 1, 0.5)

        tl.from(
          floorTexts[i + 1],
          {
            duration: 0.2,
            opacity: 0,
          },
          1
        )

        hideFloor(tl, i + 1, 1.8)

        if (i === 0) {
          // 9th floor elements

          tl.from(
            ninthFloorEls.box,
            {
              duration: 0.1,
              opacity: 0,
            },
            0.6
          )
            .from(
              ninthFloorEls.box,
              {
                duration: 0.15,
                scale: 0.96,
                transformOrigin: 'center bottom',
              },
              '>-.1'
            )
            .from(
              ninthFloorEls.girl,
              {
                duration: 0.1,
                x: -4,
                opacity: 0,
              },
              '>-.05'
            )
            .from(
              ninthFloorEls.girl,
              {
                duration: 0.2,
                x: -4,
              },
              '>-.1'
            )
            .fromTo(
              ninthFloorEls.arm,
              {
                x: 0,
                rotation: -10,
                svgOrigin: '2099 139.6',
              },
              {
                duration: 0.3,
                x: 2,
                y: -0.5,
                rotation: 10,
                svgOrigin: '2099 139.6',
              },
              '>-.2'
            )
            .to(
              ninthFloorEls.armTop,
              {
                duration: 0.3,
                rotation: -10,
                svgOrigin: '2105.1 148.2',
              },
              '>-.3'
            )
            .to(
              ninthFloorEls.armBottom,
              {
                duration: 0.3,
                rotation: 10,
                svgOrigin: '2105.1 148.2',
              },
              '>-.3'
            )
            .to(
              ninthFloorEls.envelope,
              {
                duration: 0.4,
                x: 6,
                y: 10,
                rotation: 25,
              },
              '>-.1'
            )
        } else if (i === 1) {
          // 8th floor elements

          tl.from(
            eighthFloorEls.buildings[1],
            {
              duration: 0.07,
              opacity: 0,
            },
            0.6
          )
            .from(
              eighthFloorEls.man,
              {
                duration: 0.1,
                opacity: 0,
                x: -3,
              },
              '>-.05'
            )
            .from(
              eighthFloorEls.buildings[0],
              {
                duration: 0.07,
                opacity: 0,
              },
              '>'
            )
            .from(
              eighthFloorEls.girl,
              {
                duration: 0.1,
                opacity: 0,
                x: 2,
              },
              '>-.05'
            )
            .from(
              eighthFloorEls.bubbles[0],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '0% 70%',
              },
              '>+.1'
            )
            .from(
              eighthFloorEls.bubbleTexts[0],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
            .from(
              eighthFloorEls.bubbles[1],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 70%',
              },
              '>'
            )
            .from(
              eighthFloorEls.bubbleTexts[1],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
        } else if (i === 2) {
          // 7th floor elements

          tl.from(
            seventhFloorEls.people[0],
            {
              duration: 0.05,
              opacity: 0,
            },
            0.6
          )
            .from(
              seventhFloorEls.bubbles[1],
              {
                duration: 0.05,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '0% 70%',
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbleTexts[1],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.people[1],
              {
                duration: 0.05,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbles[3],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 70%',
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbleTexts[3],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbles[2],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 20%',
              },
              '>-.05'
            )
            .from(
              seventhFloorEls.bubbleTexts[2],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.people[2],
              {
                duration: 0.05,
                opacity: 0,
              },
              '>-.1'
            )
            .from(
              seventhFloorEls.bubbles[5],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '80% 100%',
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbleTexts[5],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbles[0],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 20%',
              },
              '>-.05'
            )
            .from(
              seventhFloorEls.bubbleTexts[0],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              seventhFloorEls.bubbles[4],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 10%',
              },
              '>-.05'
            )
            .from(
              seventhFloorEls.bubbleTexts[4],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
        } else if (i === 3) {
          // 6th floor elements

          tl.from(
            sixthFloorEls.bubbles[0],
            {
              duration: 0.1,
              opacity: 0,
              scale: 0.8,
              transformOrigin: '100% 10%',
            },
            0.6
          )
            .from(
              sixthFloorEls.bubbleTexts[0],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbles[1],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '0% 100%',
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbleTexts[1],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbles[2],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '100% 20%',
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbleTexts[2],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbles[3],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '0% 100%',
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbleTexts[3],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbles[4],
              {
                duration: 0.1,
                opacity: 0,
                scale: 0.8,
                transformOrigin: '50% 100%',
              },
              '>'
            )
            .from(
              sixthFloorEls.bubbleTexts[4],
              {
                duration: 0.03,
                opacity: 0,
              },
              '>'
            )
        } else if (i === 4) {
          // 5th floor elements

          tl.from(
            fifthFloorEls.papers,
            {
              duration: 0.15,
              stagger: 0.07,
              opacity: 0,
              rotation: -10,
              transformOrigin: '50% 100%',
            },
            0.6
          )
            .from(
              fifthFloorEls.sign,
              {
                duration: 0.3,
                attr: { 'stroke-dashoffset': 27.56 },
              },
              0.75
            )
            .from(
              fifthFloorEls.pen,
              {
                duration: 0.3,
                x: -14,
                y: 4,
              },
              0.75
            )
            .fromTo(
              fifthFloorEls.pen,
              {
                opacity: 0,
                rotation: -10,
                transformOrigin: '0% 0%',
              },
              {
                duration: 0.1,
                opacity: 1,
                rotation: 5,
                transformOrigin: '0% 0%',
                ease: 'power1.inOut',
              },
              0.75
            )
            .to(
              fifthFloorEls.pen,
              {
                duration: 0.05,
                rotation: 10,
                transformOrigin: '0% 0%',
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              fifthFloorEls.pen,
              {
                duration: 0.05,
                rotation: -10,
                transformOrigin: '0% 0%',
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              fifthFloorEls.pen,
              {
                duration: 0.05,
                rotation: 5,
                transformOrigin: '0% 0%',
                ease: 'power1.inOut',
              },
              '>'
            )
            .to(
              fifthFloorEls.pen,
              {
                duration: 0.05,
                rotation: -10,
                transformOrigin: '0% 0%',
              },
              '>'
            )
        } else if (i === 5) {
          // 4th floor elements

          tl.from(
            fourthFloorEls.table,
            {
              duration: 0.03,
              y: 100,
              scale: 2,
              transformOrigin: '50% 100%',
            },
            0.6
          )
            .from(
              fourthFloorEls.man,
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              fourthFloorEls.man,
              {
                duration: 0.15,
                y: 50,
                ease: 'back.out(1.2)',
              },
              '>'
            )
            .from(
              fourthFloorEls.nameplate,
              {
                duration: 0.1,
                opacity: 0,
              },
              '>'
            )
            .from(
              fourthFloorEls.nameplate,
              {
                duration: 0.3,
                y: -25,
                ease: 'bounce.out',
              },
              '>-0.1'
            )
            .from(
              fourthFloorEls.mics[0],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[0],
              {
                duration: 0.1,
                x: -10,
                y: 12,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[1],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[1],
              {
                duration: 0.1,
                y: 12,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[2],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>-0.3'
            )
            .from(
              fourthFloorEls.mics[2],
              {
                duration: 0.1,
                x: 7,
                y: 12,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[3],
              {
                duration: 0.01,
                opacity: 0,
              },
              '>'
            )
            .from(
              fourthFloorEls.mics[3],
              {
                duration: 0.1,
                x: 12,
                y: 12,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 0,
              },
              0.8
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 1,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 0,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 1,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 0,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 1,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 0,
              },
              '>'
            )
            .to(
              fourthFloorEls.mouth,
              {
                duration: 0.1,
                scaleY: 1,
              },
              '>'
            )
        } else if (i === 6) {
          // 3nd floor

          tl.from(
            thirdFloorEls.desk,
            {
              duration: 0.1,
              opacity: 0,
            },
            0.6
          )
            .from(
              thirdFloorEls.people,
              {
                duration: 0.06,
                stagger: {
                  from: 'random',
                  amount: 0.25,
                },
                opacity: 0,
                ease: 'power2.out',
              },
              '>-0.03'
            )
            .from(
              thirdFloorEls.speaker,
              {
                duration: 0.1,
                opacity: 0,
                x: 7,
              },
              '>'
            )
            .from(
              thirdFloorEls.hand,
              {
                duration: 0.2,
                rotation: -20,
                transformOrigin: '60% 100%',
              },
              '>-0.1'
            )
        } else if (i === 7) {
          // 2nd floor

          tl.to(
            ground,
            {
              yPercent: 0,
            },
            0
          )

            .from(
              secondFloorEls.people,
              {
                duration: 0.06,
                stagger: 0.02,
                opacity: 0,
                ease: 'power4.out',
              },
              0.6
            )
            .from(
              secondFloorEls.people,
              {
                duration: 0.06,
                stagger: 0.02,
                x: -1,
              },
              0.6
            )
            .from(
              [secondFloorEls.hands, secondFloorEls.signs],
              {
                duration: 0.03,
                opacity: 0,
              },
              0.8
            )
            .from(
              secondFloorEls.hands,
              {
                duration: 0.1,
                y: 3,
              },
              0.82
            )
            .from(
              secondFloorEls.signs,
              {
                duration: 0.12,
                stagger: 0.02,
                y: 15,
                ease: 'back.out(2)',
              },
              0.82
            )
        } else if (i === 8) {
          // 1st floor

          tl.from(
            firstFloorEls.sign,
            {
              duration: 0.2,
              opacity: 0,
            },
            0.6
          )
            .from(
              firstFloorEls.base,
              {
                duration: 0.05,
                opacity: 0,
              },
              '>-0.2'
            )
            .from(
              firstFloorEls.base,
              {
                duration: 0.2,
                scale: 0.9,
                transformOrigin: 'center bottom',
              },
              '>-0.05'
            )
            .from(
              firstFloorEls.hammer,
              {
                duration: 0.1,
                opacity: 0,
              },
              '>'
            )
            .fromTo(
              firstFloorEls.hammer,
              {
                rotation: -30,
                transformOrigin: 'left bottom',
              },
              {
                duration: 0.25,
                rotation: 12,
                transformOrigin: 'left bottom',
                ease: 'power1.inOut',
              },
              '>-0.1'
            )
            .to(
              firstFloorEls.hammer,
              {
                duration: 0.15,
                rotation: 0,
                transformOrigin: 'left bottom',
                ease: 'back.out(2)',
              },
              '>'
            )
        } else {
          // Ground floor elements

          tl.from(
            groundFloorEls.papers,
            {
              duration: 0.15,
              stagger: 0.07,
              opacity: 0,
              rotation: -10,
              transformOrigin: '50% 100%',
            },
            0.6
          )
            .from(
              groundFloorEls.signCircle,
              {
                duration: 0.04,
                opacity: 0,
              },
              '>'
            )
            .from(
              groundFloorEls.signCircle,
              {
                duration: 0.1,
                attr: { r: 32 },
              },
              '>-0.04'
            )
            .from(
              groundFloorEls.signLine,
              {
                duration: 0.1,
                opacity: 0,
                attr: { x1: 1896.1, y1: 1016.1 },
              },
              '>'
            )
        }
      }

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#exit-trigger',
            end: '100% 100%',
            scrub: true,
          },
        })
        .to(
          man,
          {
            y: 50,
            scale: 2,
            transformOrigin: 'center center',
          },
          0
        )

      gsap.timeline({
        scrollTrigger: {
          trigger: '#hide-trigger',
          end: '100% 100%',
          scrub: true,
          onEnter: () => {
            $('.dhsv_vc_elevator_animation').removeClass('start-animation')
          },
          onLeaveBack: () => {
            $('.dhsv_vc_elevator_animation').addClass('start-animation')
          },
        },
      })

      // ------------------------------------------------
      //
      // Floors common functions

      function showFloor(tl, idx, pos) {
        tl.to(
          floorBacks[idx],
          {
            duration: 0.2,
            attr: {
              fill: '#ffffff',
            },
          },
          pos
        )
          .to(
            floorWalls[idx],
            {
              duration: 0.2,
              opacity: 1,
            },
            pos
          )
          .set(
            floorTexts[idx],
            {
              display: 'flex',
            },
            pos
          )
      }

      function hideFloor(tl, idx, pos) {
        tl.to(
          floorBacks[idx],
          {
            duration: 0.2,
            attr: {
              fill: '#99ABD0',
            },
          },
          pos
        )
          .to(
            floorWalls[idx],
            {
              duration: 0.2,
              opacity: 0,
            },
            pos
          )
          .to(
            floorScenes[idx],
            {
              duration: 0.2,
              opacity: 0,
            },
            pos
          )
          .to(
            floorTexts[idx],
            {
              duration: 0.2,
              opacity: 0,
            },
            pos
          )
          .set(
            floorTexts[idx],
            {
              display: 'none',
              opacity: 1,
            },
            '>'
          )
      }
    }
  })
}
